import React, { useState } from 'react'
import { Tabs } from 'antd'
import Charts from './Charts'
import Created from './Charts/Created'
import './index.less'
import { t } from 'i18next'

const { TabPane } = Tabs

const tabs = [
  { name: t('statistics.tab.myTasks'), key: 'my' },
  { name: t('statistics.tab.createdByMe'), key: 'others' },
]

const Screen = (props) => {
  const [tab, setTab] = useState(tabs[0].key)
  const onChange = (tab) => {
    setTab(tab)
  }

  return (
    <>
      <div style={{ backgroundColor: 'white', padding: '0 40px' }}>
        <Tabs activeKey={tab} onChange={onChange}>
          {tabs.map((tab) => (
            <TabPane tab={tab.name} key={tab.key} />
          ))}
        </Tabs>
      </div>
      {tab === 'my' && <Charts />}
      {tab === 'others' && <Created />}
    </>
  )
}

export default Screen
