import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import _ from 'lodash'
import { Layout, Button, Typography, Row, Col } from 'antd'
import { onCloseModal } from '@utils'
import spareNotFound from '@img/spareNotFound.png'
import carsPhotosNotFound from '@img/carsPhotosNotFound.png'
import { PartsDetails, CarsDetails, ServicesDetails } from './content'
import { Characteristics, MainTab, DescTab, Options } from './tabs'
import { Autos, Specialist } from './tabs'
import { useTranslation } from 'react-i18next'

const { Header, Content } = Layout

const { Title } = Typography

const ProductDetails = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = props
  const { spareStatus, stockType } = props
  const modals = useSelector((state) => state.app.modals)
  const index = modals.findIndex((el) => el.type === 'product-details')
  const [productData, setProductData] = useState()
  const [characteristicsData, setCharacteristicsData] = useState()
  const [optionsData, setOptionsData] = useState()
  const [photosData, setPhotosData] = useState()
  const [loading, setLoading] = useState(false)
  const [mainImg, setMainImg] = useState()
  const [visible, setVisible] = useState(false)
  const [activePhoto, setActivePhoto] = useState(0)
  const [spareData, setSpareData] = useState()
  const [servicesData, setServicesData] = useState()

  useEffect(async () => {
    setLoading(true)
    if (!spareStatus && stockType !== 'services') {
      await dispatch(
        authorizationLayer({
          url: `wh-cars/cars/main/${id}`,
          method: 'get',
        })
      ).then((res) => {
        setProductData(res.data)
      })

      await dispatch(
        authorizationLayer({
          url: `wh-cars/cars/specifications/${id}`,
          method: 'get',
        })
      ).then((res) => {
        setCharacteristicsData(res.data)
      })

      await dispatch(
        authorizationLayer({
          url: `wh-cars/cars/options/${id}`,
          method: 'get',
        })
      ).then((res) => {
        setOptionsData(res.data)
      })

      await dispatch(
        authorizationLayer({
          url: `wh-cars/cars/photos/${id}`,
        })
      )
        .then((res) => {
          setPhotosData(res.data)
          setMainImg(
            <img
              src={
                res.data?.carPhotos?.length
                  ? res.data?.carPhotos?.[0].photo
                  : carsPhotosNotFound
              }
              style={{ width: '100%', maxHeight: '100%' }}
            />
          )
        })
        .finally(() => setLoading(false))
    } else if (spareStatus && stockType !== 'services') {
      await dispatch(
        authorizationLayer({
          url: `wh-parts/spare_parts/${id}`,
        })
      )
        .then((res) => {
          setSpareData(res.data)
          setMainImg(
            <img
              src={
                res.data?.sparePartPhotos?.length
                  ? res.data?.sparePartPhotos?.[0].photo
                  : spareNotFound
              }
              style={{ width: '100%', maxHeight: '100%' }}
            />
          )
        })
        .finally(() => setLoading(false))
    } else if (stockType === 'services') {
      await dispatch(
        authorizationLayer({
          url: `wh-services/show/${id}`,
        })
      )
        .then(({ data }) => {
          setServicesData(data.data)
        })
        .finally(() => setLoading(false))
    }
  }, [dispatch])

  let tabsComponents = [
    {
      id: 1,
      content: <MainTab productData={productData} />,
      title: t('productDetails.tabs.main'),
    },
    productData?.carModification !== null
      ? {
          id: 2,
          content: (
            <Characteristics characteristicsData={characteristicsData} />
          ),
          title: t('productDetails.tabs.characteristics'),
        }
      : { id: 1000 },
    optionsData?.carOptions.length
      ? {
          id: 3,
          content: <Options optionsData={optionsData} />,
          title: t('productDetails.tabs.options'),
        }
      : { id: 1001 },
    productData?.additional_option
      ? {
          id: 4,
          content: <DescTab desc={productData?.additional_option} />,
          title: t('productDetails.tabs.desc'),
        }
      : { id: 1002 },
  ].sort((a, b) => a.id - b.id)

  let tabsComponentsServices = [
    {
      id: 1,
      content: <Autos servicesData={servicesData} />,
      title: 'Автомобили',
    },

    {
      id: 2,
      content: <Specialist servicesData={servicesData} />,
      title: 'Специалисты',
    },
  ]

  const currentImage = (id) => {
    if (!spareStatus && stockType !== 'services') {
      photosData?.carPhotos?.find((item, index) => {
        if (item.id === id) {
          setActivePhoto(index)
          return setMainImg(
            <img
              alt='car'
              src={item.photo}
              style={{ width: '100%', maxHeight: '100%' }}
            />
          )
        }
      })
    } else if (spareStatus && stockType !== 'services') {
      spareData?.sparePartPhotos.find((item, index) => {
        if (item.id === id) {
          setActivePhoto(index)
          return setMainImg(
            <img
              alt='car'
              src={item.photo}
              style={{ width: '100%', maxHeight: '100%' }}
            />
          )
        }
      })
    }
  }

  const renderContent = () => {
    if (!spareStatus && stockType !== 'services') {
      return (
        <CarsDetails
          loading={loading}
          productData={productData}
          tabsComponents={tabsComponents}
          mainImg={mainImg}
          photosData={photosData}
          setVisible={setVisible}
          visible={visible}
          activePhoto={activePhoto}
          currentImage={currentImage}
        />
      )
    } else if (spareStatus && stockType !== 'services') {
      return (
        <PartsDetails
          loading={loading}
          spareData={spareData}
          mainImg={mainImg}
          setVisible={setVisible}
          visible={visible}
          activePhoto={activePhoto}
          currentImage={currentImage}
        />
      )
    } else if (stockType === 'services') {
      return (
        <ServicesDetails
          tabsComponents={tabsComponentsServices}
          loading={loading}
          servicesData={servicesData}
        />
      )
    }
  }

  return (
    <div className='modal__popup' style={{ zIndex: 100000000 }}>
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Header>
          <Row justify='space-between' align='middle'>
            <Col>
              <Title level={4}>
                {stockType === 'services'
                  ? 'Детали услуги'
                  : t('productDetails.title')}
              </Title>
            </Col>
            <Col>
              <Button
                onClick={() =>
                  onCloseModal(dispatch, modals, 'product-details')
                }
              >
                {t('productDetails.btn.closed')}
              </Button>
            </Col>
          </Row>
        </Header>

        <div
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 64px)',
          }}
        >
          <Content
            style={{
              padding: '23px 40px 59px',
              maxWidth: '1480px',
              margin: '0 auto',
              width: '100%',
            }}
          >
            {renderContent()}
          </Content>
        </div>
      </Layout>
    </div>
  )
}

export default ProductDetails
