import React, { useState } from 'react'
import { Drawer, Tabs } from 'antd'
import { FormClient } from './components'
import { Contacts } from './components'
import { History } from './components'
import { useDispatch } from 'react-redux'
import ClientCard from '@/views/Task/components/ClientCard'
import { SET_APP } from '@store/actions/methods'
import { useTranslation } from 'react-i18next'

import './index.less'

const ClientDrawer = ({
  visibleDrawer,
  setVisibleDrawer,
  clientInfo,
  setClientInfo,
  history,
  setHistory,
  visibleHistory,
  setVisibleHistory,
  handleSearchModal,
}) => {
  const [currentTab, setCurrentTab] = useState('1')
  const [clientData, setClientData] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onChange = (key) => {
    setCurrentTab(key)
  }

  const onClose = () => {
    if (typeof handleSearchModal !== 'undefined')
      handleSearchModal(clientData?.phone_number)
    setVisibleDrawer(false)
    setCurrentTab('1')
  }

  const onCloseHistory = () => {
    setVisibleHistory(false)
    dispatch(SET_APP(['comments'], []))
  }

  return (
    <div>
      {clientInfo?.process?.showClientCard && clientInfo.client && (
        <Drawer
          title={t('clientCard.title')}
          placement='right'
          open={visibleDrawer}
          onClose={onClose}
          width={450}
          destroyOnClose={true}
          bodyStyle={{
            padding: '0',
            overflow: 'hidden',
          }}
          headerStyle={{ padding: '16px 20px' }}
        >
          {clientInfo && (
            <div className='profile'>
              <Tabs
                onChange={onChange}
                className='profile__tabs'
                defaultActiveKey={currentTab}
                destroyInactiveTabPane={true}
              >
                <Tabs.TabPane tab={t('clientCard.tabs.data')} key='1'>
                  <FormClient
                    id={clientInfo?.client?.id}
                    canEditClientCard={clientInfo?.process?.canEditClientCard}
                    setClientData={setClientData}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('clientCard.tabs.contacts')} key='2'>
                  <Contacts
                    id={clientInfo?.client?.id}
                    canEditClientCard={clientInfo?.process?.canEditClientCard}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('clientCard.tabs.serviceHistory')} key='3'>
                  <History
                    id={clientInfo?.client?.id}
                    canEditClientCard={clientInfo?.process?.canEditClientCard}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          )}
        </Drawer>
      )}

      {history && (
        <Drawer
          // title='Карточка клиента'
          placement='right'
          open={visibleHistory}
          onClose={onCloseHistory}
          width={450}
          destroyOnClose={true}
          bodyStyle={{
            padding: '0',
            overflow: 'hidden',
          }}
          headerStyle={{ padding: '16px 20px' }}
        >
          {history && <ClientCard taskData={history} />}
        </Drawer>
      )}
    </div>
  )
}

export default ClientDrawer
