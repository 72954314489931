import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SET_APP } from '@store/actions/methods'
import _ from 'lodash'
import { Button, Typography, Space } from 'antd'

const { Text } = Typography

const Counter = (props) => {
  const [customCount, setCustomCount] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    let price = 0
    props.dataForSparePartNeeds?.forEach((item) => {
      price += item.orderAmount * item.price

      if (item.id === props.data.id) {
        setCustomCount(item.orderAmount)
      }
    })
    props.setTotalPrice(price)
  }, [props])

  const countPlus = () => {
    props.dataForSparePartNeeds?.forEach((i) => {
      let find = props.dataForSparePartNeeds?.find(
        (j) => j.id === props.data.id
      )

      if (find) find.orderAmount = customCount + 1
    })
    let sorted = []
    let sortedForNeeds = []

    setCustomCount(customCount + 1)
    let obj = {
      sparePart: {
        id: props.data.id,
        price: props.data.price,
        name: props.data.name,
        amount: props.data.amount,
        reserve: props.data.reserve,
      },
      orderAmount: customCount + 1,
    }
    let objForNeeds = props.data

    objForNeeds.orderAmount = customCount + 1

    sorted = [...props.dataForSparePartTask, ...[obj]].sort(
      (a, b) => b.orderAmount - a.orderAmount
    )

    sortedForNeeds = [...props.dataForSparePartNeeds, ...[objForNeeds]].filter(
      (i) => i.orderAmount !== 0
    )

    sortedForNeeds = sortedForNeeds.filter((item, pos) => {
      return sortedForNeeds.indexOf(item) == pos
    })

    props.setDataForSparePartTask(
      _.unionBy(sorted, [obj], 'sparePart.id').filter(
        (item) => item.orderAmount !== 0
      )
    )
    props.setDataForSparePartNeeds(
      _.unionBy(sortedForNeeds, [objForNeeds], 'id')
    )

    dispatch(
      SET_APP(
        ['dataSpareTask'],
        _.unionBy(sorted, [obj], 'sparePart.id').filter(
          (item) => item.orderAmount !== 0
        )
      )
    )

    dispatch(
      SET_APP(
        ['dataSpareNeeds'],
        _.unionBy(sortedForNeeds, [objForNeeds], 'id').filter(
          (item) => item.orderAmount !== 0
        )
      )
    )
  }

  const countMinus = () => {
    props.dataForSparePartNeeds?.forEach((i) => {
      let find = props.dataForSparePartNeeds?.find(
        (j) => j.id === props.data.id
      )

      if (find) find.orderAmount = customCount - 1
    })
    let sorted = []
    let sortedForNeeds = []

    setCustomCount(customCount - 1)
    let obj = {
      sparePart: {
        id: props.data.id,
        price: props.data.price,
        name: props.data.name,
        amount: props.data.amount,
        reserve: props.data.reserve,
      },
      orderAmount: customCount - 1,
    }

    let objForNeeds = props.data
    objForNeeds.orderAmount = customCount - 1

    sorted = [...props.dataForSparePartTask, ...[obj]].sort(
      (a, b) => a.orderAmount - b.orderAmount
    )

    sortedForNeeds = [...props.dataForSparePartNeeds, ...[objForNeeds]].filter(
      (i) => i.orderAmount !== 0
    )

    sortedForNeeds = sortedForNeeds.filter((item, pos) => {
      return sortedForNeeds.indexOf(item) == pos
    })

    props.setDataForSparePartTask(
      _.unionBy(sorted, [obj], 'sparePart.id').filter(
        (item) => item.orderAmount !== 0
      )
    )
    props.setDataForSparePartNeeds(
      _.unionBy(sortedForNeeds, [objForNeeds], 'id')
    )

    dispatch(
      SET_APP(
        ['dataSpareTask'],
        _.unionBy(sorted, [obj], 'sparePart.id').filter(
          (item) => item.orderAmount !== 0
        )
      )
    )

    dispatch(
      SET_APP(
        ['dataSpareNeeds'],
        _.unionBy(sortedForNeeds, [objForNeeds], 'id').filter(
          (item) => item.orderAmount !== 0
        )
      )
    )
  }

  return (
    <Space size={16}>
      <Button
        type='primary'
        className='counter-btn '
        ghost
        onClick={() => customCount > 0 && countMinus()}
        disabled={customCount === 0}
        style={{
          color: customCount === 0 ? 'rgba(0, 0, 0, 0.25)' : '#0989FF',
        }}
      >
        <svg
          width='8'
          height='2'
          viewBox='0 0 8 2'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M-5.96046e-08 1H8'
            stroke={customCount === 0 ? 'rgba(0, 0, 0, 0.25)' : '#0989FF'}
            strokeWidth='2'
          />
        </svg>
      </Button>
      <Text>{customCount}</Text>
      <Button
        type='primary'
        className='counter-btn counter-btn--focus'
        ghost
        onClick={() =>
          props.data.amount !== 0 && props.data.amount > 0 && countPlus()
        }
        disabled={props.data.amount <= 0}
      >
        +
      </Button>
    </Space>
  )
}

export default Counter
