import React, { useContext, createContext, forwardRef } from 'react'
import { ChoiceProduct, CarReception, Files } from './components/choice'
import { Forms, Coordination } from './components/choice'
import { DepartmentsInteraction } from './components/choice'
import { Space, Typography, Divider } from 'antd'
import { TaskContext } from '../..'
import { useTranslation } from 'react-i18next'

export const ModuleContext = createContext()

const ModuleRender = forwardRef(({ data }, ref) => {
  const { t } = useTranslation()
  const { taskData, needsData, setNeedsData } = useContext(TaskContext)
  const { module } = data
  let currentNeeds = null
  let content = null
  const moduleType = module?.settings.type
  const moduleName = module?.component.componentType.name

  if (!taskData?.isForService) {
    currentNeeds = needsData?.find(
      (i) =>
        i.type === module?.component.componentType.name &&
        i.module.id === module?.id
    )
  }

  const cardTitle = (
    <>
      <Space size={module?.description ? 6 : 0} direction='vertical'>
        <div style={{ fontWeight: 500, fontSize: 16 }}>{module?.name}</div>
        <Typography.Text type='secondary'>
          {module?.description}
        </Typography.Text>
        {!module?.isRequired && (
          <div
            style={{
              padding: '0 8px',
              fontSize: 12,
              background: '#F5F6F7',
              borderRadius: 2,
              display: 'inline-flex',
              position: 'absolute',
              right: 20,
              top: 20,
            }}
          >
            {t('task.moduleRender.module.requiredMessage')}
          </div>
        )}
      </Space>
      <Divider style={{ margin: '20px 0' }} />
    </>
  )

  if (moduleType === 'choice') {
    if (moduleName === 'choiceProduct') {
      if (!taskData?.isForService) {
        currentNeeds = needsData?.find(
          (i) =>
            i.type === module?.component.componentType.name &&
            i.module.settings.stockType === module?.settings.stockType &&
            i.module.settings.type === module?.settings.type
        )
      }

      content = <ChoiceProduct ref={ref} />
    } else if (moduleName === 'carReception') {
      currentNeeds = needsData?.filter(
        (item) =>
          item.type === module?.component.componentType.name &&
          item.action === 'choice'
      )

      content = <CarReception ref={ref} />
    } else if (moduleName === 'uploadFile') {
      content = <Files ref={ref} />
    } else if (moduleName === 'form') {
      content = <Forms ref={ref} />
    }
  }

  if (moduleName === 'interactionWithDepartments') {
    content = <DepartmentsInteraction ref={ref} />
  }

  if (moduleName === 'Coordination') {
    content = <Coordination ref={ref} />
  }

  return (
    <ModuleContext.Provider
      value={{
        taskData,
        module,
        currentNeeds,
        cardTitle,
        needsData,
        setNeedsData,
      }}
    >
      {content}
    </ModuleContext.Provider>
  )
})

export default ModuleRender
