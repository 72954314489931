import React from 'react'
import { Button, Image, Modal, Tooltip, Typography } from 'antd'
import {
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
} from '@ant-design/icons'
import SkeletonTable from '@ui/SkeletonTable'
import { numberWithSpaces } from '@utils'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const { Title } = Typography

const HistoryModal = (props) => {
  const { t } = useTranslation()
  const getColumnsCars = () => {
    return [
      {
        title: t('warehouse.tab.cars.column.brand'),
        dataIndex: 'carMark',
        width: 140,
        render: (text, record) => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
        fixed: 'left',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: t('warehouse.tab.cars.column.model'),
        dataIndex: 'carModel',
        width: 140,
        render: (text, record) => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: t('warehouse.tab.parts.column.price'),
        dataIndex: 'price',
        render: (text) => (
          <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
            {`${numberWithSpaces(text)}`}
          </Tooltip>
        ),
        width: 160,
        ellipsis: {
          showTitle: false,
        },
      },
    ]
  }

  const getDataTableCars = (data) => {
    let cars = []
    data.event_value.forEach((car) => {
      cars.push({
        carMark: car.car.carMark.name,
        carModel: car.car.carModel.name,
        price: car.car.price,
      })
    })
    return cars
  }

  const getColumnsParts = () => {
    return [
      {
        title: t('warehouse.tab.parts.column.name'),
        dataIndex: 'name',
        width: 140,
        render: (text, record) => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
        fixed: 'left',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: t('task.actionHistory.modal.partsSelected'),
        dataIndex: 'orderAmount',
        width: 140,
        render: (text, record) => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: t('warehouse.tab.parts.column.inStock'),
        dataIndex: 'amount',
        width: 140,
        render: (text, record) => (
          <Tooltip placement='topLeft' title={text}>
            {text}
          </Tooltip>
        ),
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: t('warehouse.tab.parts.column.price'),
        dataIndex: 'price',
        render: (text) => (
          <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
            {`${numberWithSpaces(text)}`}
          </Tooltip>
        ),
        width: 160,
        ellipsis: {
          showTitle: false,
        },
      },
    ]
  }

  const getDataTableParts = (data) => {
    let parts = []
    data.event_value.forEach((part) => {
      parts.push({
        name: part.sparePart.name,
        orderAmount: part.orderAmount,
        amount: part.sparePart.amount,
        price: part.sparePart.price,
        sum: part.orderAmount * part.sparePart.price,
      })
    })
    return parts
  }

  return (
    <Modal
      visible={props.open}
      title={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginTop: 4 }}>{props.nameModal}</span>
          <div style={{ marginTop: 4 }}>
            <span className='modal-action-history__info'>
              {props.infoModal}
            </span>
            <span className='modal-action-history__user'>
              {props.userModal}
            </span>
          </div>
        </div>
      }
      style={{ top: 100 }}
      bodyStyle={{ paddingBottom: 40 }}
      onCancel={props.handleCancel}
      footer={false}
      width={1000}
    >
      {props.bodyModal && props.bodyModal.event_name === 'module_upload' ? (
        <>
          <Image.PreviewGroup>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {props.bodyModal.event_value?.map((i) => {
                let splitFileUrl = i.name.split('.')
                let currentDocFormat = splitFileUrl[splitFileUrl.length - 1]

                if (
                  currentDocFormat === 'jpeg' ||
                  currentDocFormat === 'jpg' ||
                  currentDocFormat === 'png' ||
                  currentDocFormat === 'jfif'
                ) {
                  return (
                    <div
                      style={{
                        marginLeft: 8,
                        marginBottom: 8,
                        display: 'inline-block',
                      }}
                      key={i.id}
                    >
                      <Image
                        src={i.path}
                        alt='file'
                        width={104}
                        height={102}
                        style={{ display: 'block' }}
                      />
                    </div>
                  )
                } else {
                  let styleIcon = {
                    fontSize: 25,
                    marginTop: 20,
                    // color: '#0989ff',
                  }
                  let icon = <FileOutlined style={styleIcon} />

                  if (currentDocFormat === 'pdf')
                    icon = <FilePdfOutlined style={styleIcon} />
                  else if (
                    currentDocFormat === 'doc' ||
                    currentDocFormat === 'docx'
                  )
                    icon = <FileWordOutlined style={styleIcon} />
                  else if (
                    currentDocFormat === 'xlsx' ||
                    currentDocFormat === 'xls'
                  )
                    icon = <FileExcelOutlined style={styleIcon} />
                  return (
                    <div
                      style={{
                        marginLeft: 8,
                        marginBottom: 8,
                        display: 'inline-block',
                      }}
                      key={i.id}
                    >
                      <Button
                        href={i.path}
                        icon={icon}
                        target='blank'
                        style={{
                          width: 104,
                          height: 104,
                        }}
                      >
                        <p
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            marginBottom: 0,
                          }}
                        >
                          {i.name}
                        </p>
                      </Button>
                    </div>
                  )
                }
              })}
            </div>
          </Image.PreviewGroup>
        </>
      ) : props.bodyModal.event_name === 'module_form' ? (
        <>
          <p className='modal-action-history__form-title'>
            {props.bodyModal.event_value.form_name}
          </p>
          <div>
            {props.bodyModal.event_value?.fields.map((field, i) => {
              if (field.fieldData !== '' && field.type !== 'multi_select') {
                return (
                  <div className='modal-action-history__form-info'>
                    <p className='modal-action-history__form-name'>
                      {field.name}:
                    </p>
                    <p className='modal-action-history__form-value'>
                      {field.fieldData}
                    </p>
                  </div>
                )
              } else if (field.type === 'multi_select') {
                if (field.fieldDataList?.length > 0) {
                  return (
                    <div className='modal-action-history__form-info-list'>
                      <p className='modal-action-history__form-name'>
                        {field.name}:
                      </p>
                      <div className='modal-action-history__form-list'>
                        {field.fieldDataList.map((item, i) => {
                          return (
                            <p className='modal-action-history__form-value'>
                              {item}
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
              }
            })}
          </div>
        </>
      ) : props.bodyModal.event_name === 'module_cars' ||
        props.bodyModal.event_name === 'module_test_drive' ||
        props.bodyModal.event_name === 'module_used_cars' ? (
        <>
          <p className='modal-action-history__wr'>
            {props.bodyModal.event_name === 'module_cars'
              ? t('task.actionHistory.modal.warehouse.newAuto')
              : props.bodyModal.event_name === 'module_test_drive'
              ? t('task.actionHistory.modal.warehouse.testDrive')
              : props.bodyModal.event_name === 'module_used_cars'
              ? t('task.actionHistory.modal.warehouse.usedAuto')
              : null}
          </p>
          {
            <SkeletonTable
              take={15}
              xScroll={false}
              className='xScroll-custom-table'
              columns={getColumnsCars(props.bodyModal)}
              loading={props.loading}
              data={getDataTableCars(props.bodyModal)}
              pagination={false}
              width={1000}
              useHeightStatus
              useHeightElements={['ant-layout-header']}
            />
          }
          {props.bodyModal.event_name === 'module_cars' ||
          props.bodyModal.event_name === 'module_used_cars' ? (
            <Title level={4} style={{ marginTop: 18 }}>
              {t('warehouse.totalPrice')}{' '}
              {getDataTableCars(props.bodyModal)
                ? `${numberWithSpaces(
                    _.sumBy(getDataTableCars(props.bodyModal), 'price')
                  )}`
                : 0}
            </Title>
          ) : null}
        </>
      ) : props.bodyModal.event_name === 'module_parts' ? (
        <>
          <p className='modal-action-history__wr'>
            {t('task.actionHistory.modal.warehouse.parts')}
          </p>
          {
            <SkeletonTable
              take={15}
              xScroll={false}
              className='xScroll-custom-table'
              columns={getColumnsParts(props.bodyModal)}
              loading={props.loading}
              data={getDataTableParts(props.bodyModal)}
              pagination={false}
              width={1000}
              useHeightStatus
              useHeightElements={['ant-layout-header']}
            />
          }
          <Title level={4} style={{ marginTop: 18 }}>
            {t('warehouse.totalPrice')}{' '}
            {getDataTableParts(props.bodyModal)
              ? `${numberWithSpaces(
                  _.sumBy(getDataTableParts(props.bodyModal), 'sum')
                )}`
              : 0}
          </Title>
        </>
      ) : props.bodyModal.event_name === 'warehouse_cars' ? (
        <>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('warehouse.tab.cars.column.brand')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carMark?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('warehouse.tab.cars.column.model')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carModel?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.year')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.year}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.body')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carBodyType?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.generation')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carGeneration?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.engine')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carEngineType?.value ||
                props.bodyModal?.event_value?.carEngineType?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.drive')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carDriveType?.value ||
                props.bodyModal?.event_value?.carDriveType?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.transmission')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carGearboxType?.value ||
                props.bodyModal?.event_value?.carGearboxType?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.rudder')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.steering_wheel === 'left'
                ? t('productDetails.cars.left')
                : t('productDetails.cars.right')}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('warehouse.tab.cars.column.mileage')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.mileage}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('warehouse.tab.cars.column.bodyColor')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carExteriorColor?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('warehouse.tab.cars.column.interiorColor')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.carInteriorColor?.name}
            </p>
          </div>
          <div className='modal-action-history__form-info'>
            <p className='modal-action-history__form-name'>
              {t('productDetails.cars.vin')}:
            </p>
            <p className='modal-action-history__form-value'>
              {props.bodyModal?.event_value?.vin_code}
            </p>
          </div>
        </>
      ) : props.bodyModal.event_name === 'module_car_reception' &&
        props.bodyModal.event_group === 'internal' ? (
        <>
          {typeof props.bodyModal?.event_value === 'object' &&
          props.bodyModal?.event_value?.length ? (
            props.bodyModal?.event_value?.map((item) => (
              <>
                <p className='modal-action-history__title-cars-view'>
                  {item.carDetail?.name}
                </p>
                <p className='modal-action-history__form-name'>
                  {t('warehouse.tab.cars.column.status')}:{' '}
                  {item.carConfirmations?.map((el, index) => (
                    <>
                      {el.name}
                      {item.carConfirmations?.length === index + 1
                        ? null
                        : ', '}
                    </>
                  ))}
                </p>
                <p className='modal-action-history__form-name'>
                  {t('task.actionHistory.modal.warehouse.comment')}:{' '}
                  {item.comment === '' ? '-' : item.comment}
                </p>
                <Image.PreviewGroup>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    {item.photos?.map((i) => {
                      return (
                        <div
                          style={{
                            marginLeft: 8,
                            marginBottom: 8,
                            display: 'inline-block',
                          }}
                          key={i.id}
                        >
                          <Image
                            src={i.photo}
                            alt='file'
                            width={104}
                            height={102}
                            style={{ display: 'block' }}
                          />
                        </div>
                      )
                    })}
                  </div>
                </Image.PreviewGroup>
              </>
            ))
          ) : (
            <Typography.Text type='secondary'>
              {t('task.actionHistory.modal.noFaults')}
            </Typography.Text>
          )}
        </>
      ) : props.bodyModal.event_name === 'module_car_reception' &&
        props.bodyModal.event_group === 'external' ? (
        <>
          {typeof props.bodyModal?.event_value === 'object' &&
          props.bodyModal?.event_value?.length ? (
            props.bodyModal?.event_value?.map((item) => (
              <>
                <p className='modal-action-history__title-cars-view'>
                  {item.carDetail?.name}
                </p>
                <p className='modal-action-history__form-name'>
                  {t('warehouse.tab.cars.column.status')}:{' '}
                  {item.carConfirmations?.map((el, index) => (
                    <>
                      {el.name}
                      {item.carConfirmations?.length === index + 1
                        ? null
                        : ', '}
                    </>
                  ))}
                </p>
                <p className='modal-action-history__form-name'>
                  {t('task.actionHistory.modal.warehouse.comment')}:{' '}
                  {item.comment === null ? '-' : item.comment}
                </p>
                <Image.PreviewGroup style={{ marginBottom: 24 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    {item.photos?.map((i) => {
                      return (
                        <div
                          style={{
                            marginLeft: 8,
                            marginBottom: 8,
                            display: 'inline-block',
                          }}
                          key={i.id}
                        >
                          <Image
                            src={i.photo}
                            alt='file'
                            width={104}
                            height={102}
                            style={{ display: 'block' }}
                          />
                        </div>
                      )
                    })}
                  </div>
                </Image.PreviewGroup>
              </>
            ))
          ) : (
            <Typography.Text type='secondary'>
              {t('task.actionHistory.modal.noFaults')}
            </Typography.Text>
          )}
        </>
      ) : props.bodyModal.event_name === 'module_car_reception' &&
        props.bodyModal.event_group === 'checklist' ? (
        <>
          <p className='modal-action-history__title-cars-view'>
            {t('task.actionHistory.modal.checkList.desc')}:
          </p>
          <p className='modal-action-history__form-name'>
            {t('task.actionHistory.modal.checkList.comment')}:
          </p>
          <p className='modal-action-history__title-cars-view'>
            {t('task.actionHistory.modal.checkList.review')}:
          </p>
          {typeof props.bodyModal?.event_value === 'object' &&
          props.bodyModal?.event_value?.length ? (
            props.bodyModal?.event_value?.map((item) => (
              <div className='modal-action-history__check-list'>
                <p className='modal-action-history__form-name'>{item?.name}:</p>
                <div>
                  {item.options?.map((el) => (
                    <p className='modal-action-history__form-name'>
                      {el?.name}
                    </p>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <Typography.Text type='secondary'>
              {t('task.actionHistory.modal.checkList.noOptions')}
            </Typography.Text>
          )}
        </>
      ) : props.bodyModal.event_name === 'module_car_reception' &&
        props.bodyModal.event_group === 'quality_control' ? (
        <>
          {typeof props.bodyModal?.event_value === 'object' &&
          props.bodyModal?.event_value?.length ? (
            props.bodyModal?.event_value?.map((item) => (
              <>
                <p className='modal-action-history__title-cars-view'>
                  {item.carCheckPoint?.name}
                </p>
                <div className='modal-action-history__check-list'>
                  <p className='modal-action-history__form-name'>
                    {t('task.actionHistory.modal.checkList.comment')}:
                  </p>
                  <p className='modal-action-history__form-name'>
                    {item.comment === null ? '-' : item.comment}
                  </p>
                </div>
              </>
            ))
          ) : (
            <Typography.Text type='secondary'>
              {t('task.actionHistory.modal.noFaults')}
            </Typography.Text>
          )}
        </>
      ) : null}
    </Modal>
  )
}

export default HistoryModal
