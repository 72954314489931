import React, { useState, createContext, useEffect, useContext } from 'react'
import './index.less'
import { Details } from './components'
import Defects from './components/Defects'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import _ from 'lodash'
import { Context } from '../..'
import { TaskContext } from '../../../../../../..'
import { ModuleContext } from '../../../../..'
import { Checkbox } from 'antd'
import data from './components/Details/data'
import { useTranslation } from 'react-i18next'

export const InspectionContext = createContext()

const Inspection = () => {
  const dispatch = useDispatch()
  const { type, taskId, moduleId, selectAuto } = useContext(Context)
  const { externalInspection, internalInspection } = useContext(Context)
  const { needsData, setNeedsData } = useContext(TaskContext)
  const { currentNeeds } = useContext(ModuleContext)
  const [values, setValues] = useState([])
  const [selected, setSelected] = useState(null)
  const [comment, setComment] = useState('')
  const [fileList, setFileList] = useState()
  const [checked, setChecked] = useState([])
  const [isEmptyExternal, setIsEmptyExternal] = useState(false)
  const [isEmptyInternal, setIsEmptyInternal] = useState(false)

  useEffect(() => {
    const getData = () => {
      if (type === 'external' && externalInspection !== null) {
        setValues(externalInspection || [])
      } else if (type === 'internal' && internalInspection !== null) {
        setValues(internalInspection || [])
      }
    }

    getData()
  }, [type])

  const needsActions = (data) => {
    let index = _.findIndex(needsData, (i) => {
      if (type === 'external') {
        return i.subType === 'externalInspection'
      } else {
        return i.subType === 'internalInspection'
      }
    })

    if (index !== -1) {
      needsData[index] = data.data[0]

      setNeedsData([...needsData])
    } else setNeedsData([...needsData, ...data.data])
  }

  const clearAll = async () => {
    await dispatch(
      authorizationLayer({
        url: `processes/tasks/${taskId}/${moduleId}/needs`,
        method: 'POST',
        data: [
          {
            type: 'carReception',
            action: 'choice',
            meta: [],
            subType:
              type === 'external' ? 'externalInspection' : 'internalInspection',
          },
        ],
      })
    ).then(({ data }) => {
      needsActions(data)
    })
    setSelected(null)

    if (type === 'external') {
      setValues(
        values?.filter((i) => {
          if (!data['external'].some((x) => x.name === i.carDetail.short_name))
            return true
          else return false
        })
      )
    } else {
      setValues(
        values?.filter((i) => {
          if (!data['internal'].some((x) => x.name === i.carDetail.short_name))
            return true
          else return false
        })
      )
    }
  }

  const toInitialState = async () => {
    await dispatch(
      authorizationLayer({
        url: `processes/tasks/${taskId}/${moduleId}/needs`,
        method: 'POST',
        data: [
          {
            type: 'carReception',
            action: 'choice',
            meta: null,
            subType:
              type === 'external' ? 'externalInspection' : 'internalInspection',
          },
        ],
      })
    ).then(({ data }) => {
      needsActions(data)
    })
  }

  useEffect(() => {
    const step = currentNeeds?.find((i) => i.subType === 'externalInspection')
    if (step?.meta === null) {
      setIsEmptyExternal(false)
    } else if (Array.isArray(step?.meta) && !step?.meta.length) {
      setIsEmptyExternal(true)
    }
  }, [currentNeeds])

  useEffect(() => {
    const step = currentNeeds?.find((i) => i.subType === 'internalInspection')
    if (step?.meta === null) {
      setIsEmptyInternal(false)
    } else if (Array.isArray(step?.meta) && !step?.meta.length) {
      setIsEmptyInternal(true)
    }
  }, [currentNeeds])

  useEffect(() => {
    setSelected(null)
  }, [type])

  return (
    <div className='inspection'>
      <InspectionContext.Provider
        value={{
          values,
          setValues,
          selected,
          setSelected,
          type,
          taskId,
          moduleId,
          selectAuto,
          clearAll,
          comment,
          setComment,
          checked,
          setChecked,
          fileList,
          setFileList,
          isEmptyExternal,
          setIsEmptyExternal,
          isEmptyInternal,
          setIsEmptyInternal,
          toInitialState,
          needsActions,
        }}
      >
        <Details />
        {selected ? <Defects /> : <Description />}
      </InspectionContext.Provider>
    </div>
  )
}

export default Inspection

const Description = () => {
  const {
    type,
    clearAll,
    isEmptyExternal,
    setIsEmptyExternal,
    isEmptyInternal,
    setIsEmptyInternal,
    toInitialState,
  } = useContext(InspectionContext)
  const { t } = useTranslation()
  const onChange = () => {
    if (type === 'external') {
      setIsEmptyExternal(!isEmptyExternal)
      if (!isEmptyExternal) clearAll()
      else toInitialState()
    } else {
      setIsEmptyInternal(!isEmptyInternal)
      if (!isEmptyInternal) clearAll()
      else toInitialState()
    }
  }

  return (
    <div style={{ padding: 24 }}>
      <div style={{ marginBottom: 20 }}>
        {t('task.moduleRender.module.carReception.inspection.problemDesc1')}
      </div>
      <div style={{ marginBottom: 12 }}>
        {t('task.moduleRender.module.carReception.inspection.problemDesc2')}
      </div>
      <Checkbox
        style={{ borderRadius: 2 }}
        checked={type === 'external' ? isEmptyExternal : isEmptyInternal}
        onChange={onChange}
      >
        {t('task.actionHistory.modal.noFaults')}
      </Checkbox>
    </div>
  )
}
