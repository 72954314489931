import { Tooltip, Typography, Button, Space, DatePicker, Skeleton } from 'antd'
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons'
import { MaskedInput } from 'antd-mask-input'
import moment from 'moment'
import { numberWithSpaces } from '@utils'
import _ from 'lodash'
import { t } from 'i18next'

const { Text } = Typography
const { RangePicker } = DatePicker

export const columns = (
  stockType,
  filters,
  markId,
  carModelId,
  filteredValue
) => {
  const statusFind = (text) => {
    const status = _.find(filters?.statusesData?.data, {
      short_name: text,
    })
    if (status) return status.name

    return <Skeleton title={true} paragraph={false} />
  }
  const inputLength = (dataIndex) => {
    if (dataIndex === 'mileage') {
      return 9
    } else if (dataIndex === 'year') {
      return 4
    } else if (dataIndex === 'price') {
      return Infinity
    } else if (dataIndex === 'engine_capacity') {
      return 3
    }
  }

  const setMasks = (dataIndex) => {
    if (
      dataIndex === 'mileage' ||
      dataIndex === 'year' ||
      dataIndex === 'price'
    ) {
      return Number
    } else {
      let rgx = /^[0-9]*\.?[0-9]*$/
      return rgx
    }
  }

  const getMilleage = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <MaskedInput
          mask={[
            {
              mask: setMasks(dataIndex),
              min: 0,
              radix: '.',
              thousandsSeparator: ' ',
            },
          ]}
          placeholder={t('choiceProduct.filters.mileagePlaceholder')}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.unmaskedValue ? [e.unmaskedValue] : [])
          }}
          allowClear
          maxLength={inputLength(dataIndex)}
        />
        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#0989ff' : undefined,
        }}
      />
    ),
  })

  const getFromTo = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <Space>
          <MaskedInput
            mask={[
              dataIndex === 'price'
                ? {
                    mask: setMasks(dataIndex),
                    min: 0,
                    radix: '.',
                    thousandsSeparator: ' ',
                  }
                : {
                    mask: setMasks(dataIndex),
                    min: 0,
                  },
            ]}
            placeholder={t('choiceProduct.filters.beforePlaceholder')}
            value={selectedKeys[0]}
            onChange={(e) => {
              if (e.target.value) {
                dataIndex === 'price'
                  ? (selectedKeys[0] = e.unmaskedValue)
                  : (selectedKeys[0] = e.target.value)

                setSelectedKeys([selectedKeys[0]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            maxLength={inputLength(dataIndex)}
            style={{ width: dataIndex === 'price' ? 200 : 100 }}
          />
          <MaskedInput
            mask={[
              dataIndex === 'price'
                ? {
                    mask: setMasks(dataIndex),
                    min: 0,
                    radix: '.',
                    thousandsSeparator: ' ',
                  }
                : {
                    mask: setMasks(dataIndex),
                    min: 0,
                  },
            ]}
            placeholder={t('choiceProduct.filters.afterPlaceholder')}
            value={selectedKeys[1]}
            onChange={(e) => {
              if (e.target.value) {
                dataIndex === 'price'
                  ? (selectedKeys[1] = e.unmaskedValue)
                  : (selectedKeys[1] = e.target.value)

                setSelectedKeys([selectedKeys[0], selectedKeys[1]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            maxLength={inputLength(dataIndex)}
            style={{ width: dataIndex === 'price' ? 200 : 100 }}
          />
        </Space>

        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
  })

  const getStockDate = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <RangePicker
          format={'DD.MM.YYYY'}
          placeholder={[
            t('choiceProduct.filters.startPlaceholder'),
            t('choiceProduct.filters.endPlaceholder'),
          ]}
          inputReadOnly
          size='small'
          onChange={(e) => setSelectedKeys(e)}
          value={selectedKeys}
        />
        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
    filterIcon: (filtered) => (
      <CalendarOutlined
        style={{
          color: filtered ? '#0989ff' : undefined,
        }}
      />
    ),
  })

  const data = [
    {
      title: t('warehouse.tab.cars.column.brand'),
      dataIndex: 'carMark',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carMark?.name ? record?.carMark?.name : null}
        >
          {record?.carMark?.name ? record?.carMark?.name : null}
        </Tooltip>
      ),
      sorter: true,
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.markData?.data.length
        ? filters?.markData?.data.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filterSearch: true,
      filteredValue: filteredValue?.carMark || null,
    },
    {
      title: t('warehouse.tab.cars.column.model'),
      dataIndex: 'carModel',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carModel?.name ? record?.carModel?.name : null}
        >
          {record?.carModel?.name ? record?.carModel?.name : null}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      fixed: 'left',
      filterSearch: true,
      filteredValue: filteredValue?.carModel || null,

      filters:
        markId && filters?.modelData?.data.length
          ? filters?.modelData?.data.map((item) => {
              const text = item.name
              const value = item.id
              const children = item.carModels?.map((i) => {
                const text = i.name
                const value = i.id

                return {
                  text,
                  value,
                }
              })

              return {
                text,
                value,
                children,
              }
            })
          : [],
      // filterMode: 'tree',
    },
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.equipment'),
          dataIndex: 'carTemplate',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={
                record?.carTemplate?.name ? record?.carTemplate?.name : null
              }
            >
              {record?.carTemplate?.name ? record?.carTemplate?.name : null}
            </Tooltip>
          ),
          width: 200,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          filters:
            carModelId && filters?.templatesData?.data.length
              ? filters?.templatesData?.data.map((item) => {
                  const text = item.name
                  const value = item.id
                  const children = item.carTemplates?.map((i) => {
                    const text = i.name
                    const value = i.id

                    return {
                      text,
                      value,
                    }
                  })

                  return {
                    text,
                    value,
                    children,
                  }
                })
              : [],
          filteredValue: filteredValue?.carTemplate || null,

          // filterSearch: true,
          // filterMode: 'tree',
        }
      : {},
    {
      title: t('warehouse.tab.cars.column.vin'),
      dataIndex: 'vin_code',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 180,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.bodyColor'),
      dataIndex: 'carExteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carExteriorColor?.name
              ? record?.carExteriorColor?.name
              : null
          }
        >
          {record?.carExteriorColor?.name
            ? record?.carExteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.exteriorColors?.data.length
        ? filters?.exteriorColors?.data.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filteredValue: filteredValue?.carExteriorColor || null,
    },
    {
      title: t('warehouse.tab.cars.column.interiorColor'),
      dataIndex: 'carInteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carInteriorColor?.name
              ? record?.carInteriorColor?.name
              : null
          }
        >
          {record?.carInteriorColor?.name
            ? record?.carInteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.interiorColors?.data.length
        ? filters?.interiorColors?.data.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filteredValue: filteredValue?.carInteriorColor || null,
    },
    {
      title: t('warehouse.tab.cars.column.year'),
      dataIndex: 'year',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      ...getFromTo('year'),
      filteredValue: filteredValue?.year || null,
    },

    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.status'),
          dataIndex: 'status',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={() => {
                const status = _.find(filters?.statusesData?.data, {
                  short_name: text,
                })
                if (status) return status.name

                return null
              }}
            >
              {statusFind(text)}
            </Tooltip>
          ),
          width: 140,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          // fixed: 'right',

          filters: filters?.statusesData?.data.length
            ? filters?.statusesData?.data.map((item) => {
                const text = item.name
                const value = item.short_name

                return {
                  text,
                  value,
                }
              })
            : [],
          filteredValue: filteredValue?.status || null,
        }
      : {},
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.arrivalDate'),
          dataIndex: 'stock_date',
          render: (text) => (
            <Tooltip
              placement='topLeft'
              title={text ? moment(text).format('DD MMMM YYYY') : null}
            >
              {text ? moment(text).format('DD MMMM YYYY') : null}
            </Tooltip>
          ),
          width: 165,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          ...getStockDate(),
          // fixed: 'left',
          filteredValue: filteredValue?.stock_date || null,
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.responsiblePerson'),
      dataIndex: 'user',
      width: 200,

      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.user?.full_name ? record?.user?.full_name : null}
        >
          {record?.user?.full_name ? record?.user?.full_name : null}
        </Tooltip>
      ),
      // fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
    },
    {
      title: t('warehouse.tab.cars.column.client'),
      dataIndex: 'client',
      width: 150,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.client?.full_name ? record?.client?.full_name : null}
        >
          {record?.client?.full_name ? record?.client?.full_name : null}
        </Tooltip>
      ),
      // fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
    },

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.price'),
          dataIndex: 'price',
          render: (text) => (
            <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
              {`${numberWithSpaces(text)}`}
            </Tooltip>
          ),
          width: 160,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          ...getFromTo('price'),
          filteredValue: filteredValue?.price || null,
        }
      : {},
    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.mileage'),
          dataIndex: 'mileage',
          render: (text) => (
            <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
              {`${numberWithSpaces(text)}`}
            </Tooltip>
          ),
          width: 140,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },

          ...getMilleage('mileage'),
          filteredValue: filteredValue?.mileage || null,
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.engine'),
      dataIndex: 'engine_capacity',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      ...getFromTo('engine_capacity'),
      filteredValue: filteredValue?.engine_capacity || null,
    },
    {
      title: t('warehouse.tab.cars.column.gearboxType'),
      dataIndex: 'carGearboxType',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carGearboxType?.short_value
              ? record?.carGearboxType?.short_value
              : null
          }
        >
          {record?.carGearboxType?.short_value
            ? record?.carGearboxType?.short_value
            : null}
        </Tooltip>
      ),
      width: 120,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.terms'),
          dataIndex: 'conditions',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={
                record?.conditions !== null
                  ? record?.conditions?.map((item, index) => {
                      return <p key={index}>{item}</p>
                    })
                  : ''
              }
            >
              {record?.conditions !== null
                ? record?.conditions?.map((item, index) => {
                    return <Text key={index}>{item}</Text>
                  })
                : ''}
            </Tooltip>
          ),
          width: 140,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          filters: filters?.conditions?.length
            ? filters?.conditions?.map((item) => {
                return item
              })
            : [],
          filteredValue: filteredValue?.conditions || null,
        }
      : {},
  ]

  return data
}

export const columnsForSpareParts = (filters) => {
  const getFromTo = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <Space>
          <MaskedInput
            mask={[
              {
                mask: Number,
                min: 0,
                radix: '.',
                thousandsSeparator: ' ',
              },
            ]}
            placeholder={t('choiceProduct.filters.beforePlaceholder')}
            value={selectedKeys[0]}
            onChange={(e) => {
              if (e.target.value) {
                selectedKeys[0] = e.unmaskedValue

                setSelectedKeys([selectedKeys[0]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            style={{ width: 200 }}
          />
          <MaskedInput
            mask={[
              {
                mask: Number,
                min: 0,
                radix: '.',
                thousandsSeparator: ' ',
              },
            ]}
            placeholder={t('choiceProduct.filters.afterPlaceholder')}
            value={selectedKeys[1]}
            onChange={(e) => {
              if (e.target.value) {
                selectedKeys[1] = e.unmaskedValue

                setSelectedKeys([selectedKeys[0], selectedKeys[1]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            style={{ width: 200 }}
          />
        </Space>

        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
  })
  const data = [
    {
      title: t('warehouse.tab.parts.column.category'),
      dataIndex: 'sparePartCategory',
      width: 220,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.sparePartCategory?.name
              ? record?.sparePartCategory?.name
              : null
          }
        >
          {record?.sparePartCategory?.name
            ? record?.sparePartCategory?.name
            : null}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.sparePartsCategory?.data.length
        ? filters?.sparePartsCategory?.data.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filterSearch: true,
    },
    {
      title: t('warehouse.tab.parts.column.name'),
      dataIndex: 'name',
      width: 280,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.article'),
      dataIndex: 'article_number',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.inStock'),
      dataIndex: 'amount',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)} `}>
          {`${numberWithSpaces(text)} `}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.reserve'),
      dataIndex: 'reserve',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)} `}>
          {`${numberWithSpaces(text)} `}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.price'),
      dataIndex: 'price',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
          {`${numberWithSpaces(text)}`}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      ...getFromTo(),
    },
  ]

  return data
}

export const columnsForServices = (filters) => {
  const data = [
    {
      title: t('warehouse.tab.parts.column.category'),
      dataIndex: 'category',
      width: 300,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.category?.name ? record?.category?.name : null}
        >
          {record?.category?.name ? record?.category?.name : null}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.servicesCategory?.data.data.length
        ? filters?.servicesCategory?.data.data.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filterSearch: true,
    },
    {
      title: t('warehouse.tab.parts.column.name'),
      dataIndex: 'name',
      width: 300,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Автомобили',
      dataIndex: 'carSpecifications',
      render: (_, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record.carSpecifications?.length === 0
              ? 'Все'
              : record.carSpecifications?.length
          }
        >
          {record.carSpecifications?.length === 0 ? (
            <span style={{ color: '#8c8c8c' }}>Все</span>
          ) : (
            record.carSpecifications?.length
          )}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Длительность (час)',
      dataIndex: 'duration',
      render: (_, record) => {
        let minDuration = ''
        if (record.carSpecifications?.length > 0) {
          let arrayDuration = []
          record.carSpecifications?.forEach((item) => {
            arrayDuration.push(item.duration)
          })
          minDuration = Math.min.apply(null, arrayDuration)
        }

        return (
          <Tooltip
            placement='topLeft'
            title={
              record.carSpecifications?.length === 0
                ? record.duration
                : `от ${minDuration}`
            }
          >
            {record.carSpecifications?.length === 0
              ? record.duration
              : `от ${minDuration}`}
          </Tooltip>
        )
      },
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Стоимость',
      dataIndex: 'cost',
      render: (_, record) => {
        let minCost = ''
        if (record.carSpecifications?.length > 0) {
          let arrayCost = []
          record.carSpecifications?.forEach((item) => {
            arrayCost.push(item.cost)
          })
          minCost = Math.min.apply(null, arrayCost)
        }

        return (
          <Tooltip
            placement='topLeft'
            title={
              record.carSpecifications?.length === 0
                ? numberWithSpaces(record.cost)
                : `от ${numberWithSpaces(minCost)}`
            }
          >
            {record.carSpecifications?.length === 0
              ? numberWithSpaces(record.cost)
              : `от ${numberWithSpaces(minCost)}`}
          </Tooltip>
        )
      },
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
  ]

  return data
}

export const nestedColumns = [
  {
    title: t('warehouse.tab.cars.column.brand'),
    dataIndex: 'mark',
    width: 300,
    render: (text, record) => (
      <Tooltip placement='topLeft' title={text}>
        {text}
      </Tooltip>
    ),
  },
  {
    title: t('warehouse.tab.cars.column.model'),
    dataIndex: 'model',
    width: 300,
    render: (text, record) => (
      <Tooltip placement='topLeft' title={text}>
        {text}
      </Tooltip>
    ),
  },
  // {
  //   title: "Поколение",
  //   dataIndex: "generation",
  // },
  // {
  //   title: "Тип двигателя",
  //   dataIndex: "engine_type",
  // },
  // {
  //   title: "Объем",
  //   dataIndex: "engine_capacity",
  // },
  {
    title: 'Длительность',
    dataIndex: 'duration',
    render: (text, record) => (
      <Tooltip placement='topLeft' title={text}>
        {text}
      </Tooltip>
    ),
  },
  {
    title: 'Стоимость',
    dataIndex: 'cost',
    render: (text, record) => (
      <Tooltip placement='topLeft' title={numberWithSpaces(record.cost)}>
        {numberWithSpaces(record.cost)}
      </Tooltip>
    ),
  },
]
