import React, { useState, useEffect, useContext } from 'react'
import { useRef, createRef } from 'react'
import _ from 'lodash'
import { Card } from 'antd'
import './index.less'
import { Footer, ModuleRender } from '@views/Task/components'
import { TaskContext } from '../..'

const Modules = () => {
  const { id, taskData, loading, needsData, cancelReasons } =
    useContext(TaskContext)
  const [currenStepStage, setCurrentStepStage] = useState(0)

  const mRefs = useRef([])

  useEffect(() => {
    taskData?.process?.stages?.find((item, index) => {
      if (item.id === taskData?.currentStage?.id) {
        setCurrentStepStage(index)
      }
    })
  }, [taskData])

  const validateModules = async () => {
    let status = true
    for (let i = 0; i < taskData?.currentStage?.modules.length; i++) {
      const values = await mRefs.current[i].current?.getStatus()
      if (!values) {
        status = taskData?.currentStage?.modules[i].name
        break
      }
    }

    return status
  }

  return (
    <div className='task__stages'>
      <div className='task__header'>
        {taskData?.process?.stages?.map((stage, index) => {
          return (
            <div
              className={
                currenStepStage === index || currenStepStage > index
                  ? 'task__header-item is-active'
                  : 'task__header-item '
              }
              key={stage.id}
              style={
                currenStepStage === index
                  ? { fontWeight: 500 }
                  : { color: ' rgba(41, 52, 82, 0.4)' }
              }
            >
              {stage.name}
            </div>
          )
        })}
      </div>
      <div className='task__steps'>
        {taskData?.currentStage?.modules?.map((item, index) => {
          if (!mRefs.current[index]) mRefs.current[index] = createRef()
          return (
            <Card
              key={item.id}
              style={{
                maxHeight: 660,
                minHeight:
                  item.component.componentType.name === 'carReception'
                    ? 660
                    : 'auto',
                borderRadius: 6,
                boxShadow: '0px 2px 12px rgba(130, 153, 219, 0.08)',
                border: '1px solid #E3E6E9',
                marginBottom: 20,
              }}
              bodyStyle={{ padding: 20 }}
            >
              <ModuleRender
                ref={mRefs.current[index]}
                data={{
                  module: item,
                }}
              />
            </Card>
          )
        })}
      </div>
      <Footer validateModules={validateModules} />
    </div>
  )
}

export default Modules
