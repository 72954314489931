import React, { useState, useEffect, useContext, createContext } from 'react'
import { useMemo } from 'react'
import { Form, Spin, Divider } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import { Brand, Model, Mileage } from '../CarInfo/components'
import { MainInfo, Colors, VIN } from './components'
import { LoadingOutlined } from '@ant-design/icons'
import { Context } from '@views/Task/components/ModuleRender/components/choice/CarReception'
import _ from 'lodash'
import { TaskContext } from '../../../../../../..'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
)

export const CharacteristicsContext = createContext()

const Characteristics = () => {
  const { selectAuto, taskId, moduleId, comment } = useContext(Context)
  const { setNeedsData, needsData } = useContext(TaskContext)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [brandId, setBrandId] = useState(selectAuto?.carMark?.id)
  const [modelId, setModelId] = useState(selectAuto?.carModel?.id)
  const [mileageError, setMileageError] = useState(false)
  const mileage = Form.useWatch('mileage', form)
  const brand = Form.useWatch('brand', form)
  const model = Form.useWatch('model', form)
  const [previousValues, setPreviousValues] = useState({
    year: selectAuto?.year,
    carcase: selectAuto?.carBodyType?.id,
    generation: selectAuto?.carGeneration?.id,
    enigne: selectAuto?.carEngineType?.id,
    drive: selectAuto?.carDriveType?.id,
    transmission: selectAuto?.carGearboxType?.id,
    modification: selectAuto?.carModification?.id,
  })

  useEffect(() => {
    if (selectAuto) {
      setBrandId(selectAuto?.carMark?.id)
      setModelId(selectAuto?.carModel?.id)
      form.setFieldsValue({
        brand: selectAuto?.carMark?.name,
        model: selectAuto?.carModel?.name,
        year: selectAuto?.year,
        carcase: selectAuto?.carBodyType?.id,
        generation: selectAuto?.carGeneration?.id,
        engine: selectAuto?.carEngineType?.id,
        drive: selectAuto?.carDriveType?.id,
        transmission: selectAuto?.carGearboxType?.id,
        rudder: selectAuto?.steering_wheel,
        modification: !selectAuto?.engine_capacity
          ? selectAuto?.carModification?.id
          : 'other',
        mileage: selectAuto?.mileage?.toString(),
        exterior_color: selectAuto?.carExteriorColor?.id,
        interior_color: selectAuto?.carInteriorColor?.id,
        vin: selectAuto?.vin_code?.toUpperCase(),
        mileage_type: selectAuto?.unitMileage || 'km',
        volume: selectAuto?.engine_capacity?.toString(),
      })
    }
  }, [selectAuto])

  useEffect(() => {
    if (!brandId) {
      setModelId()
      form.resetFields(['model'])
    }
  }, [brandId])

  useEffect(() => {
    if (brand === '') {
      setBrandId()
      updateInfo()
      form.setFieldsValue({
        model: undefined,
        year: undefined,
        carcase: undefined,
        generation: undefined,
        engine: undefined,
        drive: undefined,
        transmission: undefined,
        modification: undefined,
        volume: undefined,
      })
    }
  }, [brand])

  useEffect(() => {
    if (model === '') {
      setModelId()
      updateInfo()
      form.setFieldsValue({
        year: undefined,
        carcase: undefined,
        generation: undefined,
        engine: undefined,
        drive: undefined,
        transmission: undefined,
        modification: undefined,
        volume: undefined,
      })
    }
  }, [model])

  useEffect(() => {
    if (!modelId) {
      form.resetFields(['year'])
    }
  }, [modelId])

  useEffect(() => {
    if (mileage === '') setMileageError(true)
    else setMileageError(false)
  }, [mileage])

  const loadingComponent = <Spin indicator={antIcon} />

  const getName = (name) => {
    const selected = form.getFieldValue(name)
    const picker = document.getElementById(selected)

    if (picker) return picker.innerHTML
    return ''
  }

  const getField = (name) => {
    const item = form.getFieldValue(name)
    if (!item) return null

    return {
      id: form.getFieldValue(name),
      name: getName(name),
    }
  }

  const updateInfo = useMemo(
    () =>
      _.debounce(async (brandId, modelId) => {
        await dispatch(
          authorizationLayer({
            url: `processes/tasks/${taskId}/${moduleId}/needs`,
            method: 'POST',
            data: [
              {
                type: 'carReception',
                action: 'choice',
                meta: {
                  carMark: brandId
                    ? {
                        name: form.getFieldValue('brand'),
                        id: brandId,
                      }
                    : null,
                  carModel: modelId
                    ? {
                        name: form.getFieldValue('model'),
                        id: modelId,
                      }
                    : null,
                  year: form.getFieldValue('year') || null,
                  carBodyType: getField('carcase'),
                  carGeneration: getField('generation'),
                  carEngineType: getField('engine'),
                  carDriveType: getField('drive'),
                  carGearboxType: getField('transmission'),
                  steering_wheel: form.getFieldValue('rudder') || null,
                  carModification: getField('modification'),
                  mileage:
                    parseInt(
                      form.getFieldValue('mileage')?.replace(/\s/g, '')
                    ) || null,
                  carExteriorColor: getField('exterior_color'),
                  carInteriorColor: getField('interior_color'),
                  vin_code: form.getFieldValue('vin') || null,
                  engine_capacity:
                    form.getFieldValue('modification') === 'other'
                      ? form.getFieldValue('volume')
                      : null,
                  unitMileage: form.getFieldValue('mileage_type') || null,
                  additional_option: comment,
                },
                subType: 'choiceCar',
              },
            ],
          })
        ).then(({ data }) => {
          setPreviousValues({
            year: data.year,
            carcase: data.carBodyType?.id,
            generation: data.carGeneration?.id,
            enigne: data.carEngineType?.id,
            drive: data.carDriveType?.id,
            transmission: data.carGearboxType?.id,
            modification: data.carModification?.id,
          })

          let index = _.findIndex(needsData, (i) => i.subType === 'choiceCar')

          if (index !== -1) {
            needsData[index] = data.data[0]

            setNeedsData([...needsData])
          } else setNeedsData([...needsData, ...data.data])
        })
      }, 500),
    [comment]
  )

  return (
    <div className='car-info'>
      <Form
        form={form}
        className='car-info__form'
        autoComplete='off'
        layout='vertical'
        preserve={false}
        initialValues={{
          mileage_type: 'km',
        }}
      >
        <CharacteristicsContext.Provider
          value={{
            loadingComponent,
            brandId,
            setBrandId,
            modelId,
            mileageError,
            setModelId,
            updateInfo,
            mileage,
            selectAuto,
            previousValues,
          }}
        >
          <Brand />
          {brandId && <Model />}
          {brandId && modelId && (
            <>
              <Divider style={{ margin: '44px 0 20px' }} />
              <MainInfo />
              <Divider style={{ margin: '20px 0' }} />
              <Mileage />
              <Divider style={{ margin: '20px 0' }} />
              <Colors />
              <Divider style={{ margin: '20px 0' }} />
              <VIN />
            </>
          )}
        </CharacteristicsContext.Provider>
      </Form>
    </div>
  )
}

export default Characteristics
