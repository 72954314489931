import React, { useState, useEffect, createContext, useContext } from 'react'
import { useMemo, forwardRef, useImperativeHandle } from 'react'
import { Tabs, Form } from 'antd'
import { authorizationLayer } from '@store/actions/methods'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import './index.less'
import {
  CarInfo,
  Inspection,
  Characteristics,
  TestDrive,
  CheckList,
} from './components'
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { ModuleContext } from '../../..'
import { useTranslation } from 'react-i18next'

export const Context = createContext()

const CarReception = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { module, taskData, currentNeeds, cardTitle } =
    useContext(ModuleContext)
  const [currentStep, setCurrentStep] = useState('choiceCar')
  const [mainInfo, setMainInfo] = useState({})
  const [comment, setComment] = useState(
    currentNeeds?.find((i) => i.subType === 'choiceCar')?.meta
      ?.additional_option
  )

  useEffect(() => {
    getInfo()
  }, [currentStep, currentNeeds])

  const getInfo = () => {
    let choiceCar = currentNeeds?.find((i) => i.subType === 'choiceCar')
    let externalInspection = currentNeeds?.find(
      (i) => i.subType === 'externalInspection'
    )
    let internalInspection = currentNeeds?.find(
      (i) => i.subType === 'internalInspection'
    )
    let checkList = currentNeeds?.find((i) => i.subType === 'checkList')
    let testDrive = currentNeeds?.find((i) => i.subType === 'testDrive')

    setMainInfo({
      selectAuto: choiceCar?.meta,
      externalInspection: externalInspection?.meta,
      internalInspection: internalInspection?.meta,
      checkList: checkList?.meta,
      testDriveResults: testDrive?.meta,
    })
  }

  const renderStatus = (carData) => {
    let status = false

    if (carData?.engine_capacity || carData?.carModification?.id === null) {
      if (
        carData?.carMark &&
        carData?.carModel &&
        carData?.year &&
        carData?.carBodyType &&
        carData?.carGeneration &&
        carData?.carEngineType &&
        carData?.carDriveType &&
        carData?.carGearboxType &&
        carData?.steering_wheel &&
        carData?.mileage &&
        carData?.carExteriorColor &&
        carData?.carInteriorColor &&
        carData?.vin_code &&
        carData?.unitMileage &&
        carData?.engine_capacity
      ) {
        status = true
      }
    } else {
      if (
        !carData?.carMark ||
        !carData?.carModel ||
        !carData?.year ||
        !carData?.carBodyType ||
        !carData?.carGeneration ||
        !carData?.carEngineType ||
        !carData?.carDriveType ||
        !carData?.carGearboxType ||
        !carData?.steering_wheel ||
        !carData?.mileage ||
        !carData?.carExteriorColor ||
        !carData?.carInteriorColor ||
        !carData?.vin_code ||
        !carData?.unitMileage ||
        !carData?.carModification
      ) {
        status = false
      } else status = true
    }

    return status
  }

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        if (module?.isRequired) {
          const choiceCar = currentNeeds?.find(
            (i) => i.subType === 'choiceCar'
          )?.meta

          const stepsSettings = module?.settings.admissionSteps?.length || 0

          if (stepsSettings) {
            if (
              renderStatus(choiceCar) &&
              stepsSettings === currentNeeds?.length - 1
            ) {
              return true
            } else if (
              renderStatus(choiceCar) &&
              stepsSettings === currentNeeds?.length &&
              choiceCar?.additional_option
            ) {
              return true
            } else return false
          } else {
            if (renderStatus(choiceCar)) return true
          }
        } else return true
      },
    }),
    [currentNeeds]
  )

  const renderTabsLabel = useMemo(
    () => (label) => {
      let title = null
      let icon = <ExclamationCircleOutlined style={{ color: '#FF9900' }} />
      const empty = <ExclamationCircleOutlined style={{ color: '#FF9900' }} />
      const check = <CheckCircleOutlined style={{ color: '#3BD378' }} />
      const step = currentNeeds?.find((i) => i.subType === label)

      if (label === 'choiceCar') {
        title = t('task.moduleRender.module.carReception.tab.charachteristics')
        if (renderStatus(step?.meta)) {
          icon = check
        } else {
          icon = empty
        }
      } else if (
        label === 'externalInspection' ||
        label === 'internalInspection'
      ) {
        title =
          label === 'externalInspection'
            ? t('task.moduleRender.module.carReception.tab.exteriorInspection')
            : t('task.moduleRender.module.carReception.tab.interiorInspection')
        if (step?.meta === null) {
          icon = empty
        } else if (Array.isArray(step?.meta)) {
          icon = check
        }
      } else if (label === 'checkList') {
        title = t('task.moduleRender.module.carReception.tab.options')
        if (step?.meta === null || !mainInfo?.selectAuto?.additional_option) {
          icon = empty
        }
        if (
          Array.isArray(step?.meta) ||
          mainInfo?.selectAuto?.additional_option ||
          comment
        ) {
          icon = check
        }
      } else if (label === 'testDrive') {
        title = t('task.moduleRender.module.carReception.tab.testDrive')
        if (step?.meta === null) {
          icon = empty
        } else if (Array.isArray(step?.meta)) {
          icon = check
        }
      }
      return (
        <span>
          {icon} {title}
        </span>
      )
    },
    [currentNeeds, comment]
  )

  const stepsData = [
    // {
    //   title: 'Среднерыночная стоимость',
    //   key: '1',
    // },
    {
      title: renderTabsLabel('choiceCar'),
      key: 'choiceCar',
    },
    {
      title: renderTabsLabel('externalInspection'),
      key: 'externalInspection',
    },
    {
      title: renderTabsLabel('internalInspection'),
      key: 'internalInspection',
    },
    {
      title: renderTabsLabel('checkList'),
      key: 'checkList',
    },

    {
      title: renderTabsLabel('testDrive'),
      key: 'testDrive',
    },
  ]

  const renderContent = () => {
    let content = null
    // if (currentStep === '1') content = <CarInfo />
    if (currentStep === 'choiceCar') content = <Characteristics />
    else if (
      currentStep === 'externalInspection' ||
      currentStep === 'internalInspection'
    )
      content = <Inspection />
    else if (currentStep === 'testDrive') content = <TestDrive />
    else if (currentStep === 'checkList') content = <CheckList />

    return (
      <Context.Provider
        value={{
          selectAuto: mainInfo?.selectAuto,
          taskId: taskData?.id,
          moduleId: module?.id,
          addCarToWarehouse: module?.settings.addCarToWarehouse,
          type: currentStep === 'externalInspection' ? 'external' : 'internal',
          externalInspection: mainInfo?.externalInspection,
          internalInspection: mainInfo?.internalInspection,
          checkList: mainInfo?.checkList,
          testDriveResults: mainInfo?.testDriveResults,
          currentNeeds,
          setComment,
          comment,
        }}
      >
        {content}
      </Context.Provider>
    )
  }

  return (
    <>
      {cardTitle}
      <Tabs
        type='card'
        onChange={(e) => setCurrentStep(e)}
        className='car-reception__tabs'
        items={stepsData?.map((i) => {
          if (
            module?.settings?.admissionSteps?.includes(i.key) ||
            i.key === '1' ||
            i.key === 'choiceCar'
          )
            return {
              label: i.title,
              key: i.key,
            }
        })}
      />
      <div className='car-reception__tabs-content'>{renderContent()}</div>
    </>
  )
})

export default CarReception
