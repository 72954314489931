import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import './index.less'
import { Popover, Typography, Drawer, List } from 'antd'
import Loading from '@ui/Loading'
import moment from 'moment'
import ClientHistory from '@views/Task/components/ClientCard/components/ClientHistory'
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import { t } from 'i18next'

const { Text } = Typography

const getPopoverContent = (
  autoDataNew,
  autoDataUsed,
  autoDataTestDrive,
  partsData,
  servicesData
) => {
  return (
    <div
      style={{
        maxHeight: '100px',
        overflowY: 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
      className='custom-popover-inner'
    >
      {autoDataNew?.length ? (
        <Text type='secondary'>{t('clientCard.history.newAuto')}</Text>
      ) : null}
      {autoDataNew?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataUsed?.length ? (
        <Text type='secondary'>{t('clientCard.history.usedAuto')}</Text>
      ) : null}
      {autoDataUsed?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataTestDrive?.length ? (
        <Text type='secondary'>{t('clientCard.history.testDrive')}</Text>
      ) : null}
      {autoDataTestDrive?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {partsData?.length ? (
        <Text type='secondary'>{t('clientCard.history.parts')}</Text>
      ) : null}
      {partsData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {servicesData?.length ? (
        <Text type='secondary'>{t('clientCard.history.service')}</Text>
      ) : null}
      {servicesData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}
//TODO

const History = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const dispatch = useDispatch()

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  })

  const getData = async () => {
    try {
      setLoading(true)

      let q = {
        page: pagination.current,
        limit: pagination.pageSize,
      }
      const query = queryString.stringify(q)

      const {
        data: { data: items },
      } = await dispatch(
        authorizationLayer({
          url: `processes/tasks/get-tasks-by-client-id/${id}?${query}`,
          method: 'get',
        })
      )

      if (items.length === 0) setHasMore(false)

      setData([...data, ...items])
      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    getData()
  }, [pagination.current])

  const needsItem = (record) => {
    let autoDataNew = record.clientData ? record.clientData.cars.new : []
    let autoDataUsed = record.clientData ? record.clientData.cars.used : []
    let autoDataTestDrive = record.clientData
      ? record.clientData.cars.test_drive
      : []

    let partsData = record.clientData ? record.clientData?.parts : []
    let servicesData = record.clientData ? record.clientData?.services : []
    return (
      <span
        style={{
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {!autoDataNew?.length &&
        !autoDataUsed?.length &&
        !autoDataTestDrive?.length &&
        !partsData?.length &&
        !servicesData?.length ? (
          <Text>Потребность не выбрана</Text>
        ) : (
          <Popover
            overlayClassName='custom-popover'
            trigger='hover'
            placement='bottomLeft'
            title={
              <div onClick={(e) => e.stopPropagation()}>{`${t(
                'clientCard.history.selected'
              )} ${
                autoDataNew?.length +
                partsData?.length +
                servicesData?.length +
                autoDataUsed?.length +
                autoDataTestDrive?.length
              }`}</div>
            }
            content={getPopoverContent(
              autoDataNew,
              autoDataUsed,
              autoDataTestDrive,
              partsData,
              servicesData
            )}
          >
            <Text
              style={{
                cursor: 'pointer',
              }}
              // type='secondary'
            >{`${t('clientCard.history.selectedNeed')} ${
              autoDataNew?.length +
              partsData?.length +
              servicesData?.length +
              autoDataUsed?.length +
              autoDataTestDrive?.length
            }`}</Text>
          </Popover>
        )}
      </span>
    )
  }

  const [open, setOpen] = useState(false)
  const [list, setList] = useState()

  const showDrawer = (item) => {
    setList(item)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className='history'>
      {loading && <Loading spinner={true} />}

      <div
        className='history__list'
        id='scrollableDiv'
        // style={{ overflow: 'scroll', height: '85vh' }}
      >
        {data && (
          <InfiniteScroll
            dataLength={data?.length}
            next={() => {
              setPagination((value) => ({
                ...value,
                current: value.current + 1,
              }))
            }}
            hasMore={hasMore}
            scrollableTarget='scrollableDiv'
          >
            <List
              dataSource={data}
              renderItem={(item) => (
                <>
                  <List.Item
                    key={item.id}
                    className='history__item'
                    onClick={(e) => {
                      e.stopPropagation()
                      showDrawer(item)
                    }}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'normal',
                      padding: '12px 20px 5px 20px',
                      borderBottom: '1px solid #fafafa',
                      cursor: 'pointer',
                    }}
                  >
                    <List.Item.Meta />
                    <div className='history__box'>
                      <p className='history__title history__p'>
                        {needsItem(item)}
                      </p>
                      <p className='history__id history__p'>{item?.inner_id}</p>
                    </div>

                    <div className='history__box history__box--date'>
                      <p className='history__subtitle history__p'>
                        {item?.currentStage?.name}
                      </p>
                      <p className='history__date history__p'>
                        {moment(item?.created_at).format('DD MMMM YYYY, HH:mm')}
                      </p>
                    </div>
                  </List.Item>
                </>
              )}
            />
          </InfiniteScroll>
        )}
      </div>

      <Drawer
        title={t('clientCard.history.actionHistory')}
        placement='right'
        onClose={onClose}
        visible={open}
        width={450}
        destroyOnClose={true}
        bodyStyle={{ padding: '0' }}
      >
        {<ClientHistory data={{ taskData: list }} />}
      </Drawer>
    </div>
  )
}

export default History
