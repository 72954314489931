import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import moment from 'moment'
import _ from 'lodash'
import { Button, Row, Col, Space, Modal } from 'antd'
import { Form, Spin, notification } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'
import './index.less'
import { PausedModal, CancelModal } from '@views/Task/components'
import { useNavigate } from 'react-router'
import { TaskContext } from '../..'
import { setNext } from '@views/Task/hooks/setNext'
import { useTranslation } from 'react-i18next'

const Footer = ({ validateModules }) => {
  const {
    id,
    loading,
    taskData,
    cancelReasons,
    setCurrentStageData,
    setTaskData,
  } = useContext(TaskContext)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [currentModal, setCurrentModal] = useState(1)
  const [cancelReasonValue, setCancelReasonValue] = useState()
  const [textAreaValue, setTextAreaValue] = useState('')
  const [textAreaStatus, setTextAreaStatus] = useState(true)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const date = Form.useWatch('date', form)
  const comments = Form.useWatch('comments', form)
  const [btnStatus, setbtnStatus] = useState(true)
  const navigate = useNavigate()
  const [nextStageId, setNextStageId] = useState('')
  const [nextStageModuleId, setNextStageModuleId] = useState('')
  const [replaceToEndStatus, setReplaceToEndStatus] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)

  const disabledDate = (current) => {
    // Can not select days before today and today
    // return current && current < moment().endOf('day')
    return current.isBefore(moment(), 'day')
  }

  const showModalCancel = () => {
    setVisible(true)
    setCurrentModal(1)
    dispatch(SET_APP(['comments'], []))
  }

  const showModalPaused = () => {
    setVisible(true)
    setCurrentModal(2)
    dispatch(SET_APP(['comments'], []))
  }

  const onChangeRadio = (e) => {
    setCancelReasonValue(e.target.value)
  }

  const onChangeTextArea = (e) => {
    setTextAreaValue(e.target.value)
  }
  const funcToPost = async () => {
    setLoadingBtn(true)
    await dispatch(
      authorizationLayer({
        url:
          currentModal === 1
            ? `processes/tasks/${id}/cancel`
            : `processes/tasks/${id}/pause`,
        method: 'post',
        data:
          currentModal === 1
            ? cancelReasonValue !== 'other'
              ? {
                  cancelReasonId: cancelReasonValue,
                }
              : {
                  cancelReasonId: null,
                  cancelComment: textAreaValue,
                }
            : {
                pausedTo: form.getFieldValue('date').format('YYYY-MM-DD'),
                pauseComment: form.getFieldValue('comments'),
              },
      })
    )
      .then((res) => {
        if (res.status === 201) {
          navigate('/')

          setLoadingBtn(false)
        }
      })
      .finally(() => setLoadingBtn(false))
  }

  useEffect(() => {
    if (cancelReasonValue === 'other') {
      setTextAreaStatus(false)
    } else {
      setTextAreaStatus(true)
    }
  }, [cancelReasonValue])

  useEffect(() => {
    if (cancelReasonValue && currentModal === 1) {
      if (cancelReasonValue !== 'other') {
        setbtnStatus(false)
      } else if (cancelReasonValue === 'other' && textAreaValue) {
        setbtnStatus(false)
      } else {
        setbtnStatus(true)
      }
    } else {
      if (date && comments) {
        setbtnStatus(false)
      } else {
        setbtnStatus(true)
      }
    }
  }, [cancelReasonValue, currentModal, textAreaValue, date, comments])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const nextStage = async () => {
    const isValid = await validateModules()
    if (_.isString(isValid)) {
      return notification.warn({
        message: t('task.footer.warnMessage'),
        description: isValid,
      })
    }

    setIsModalOpen(true)
    await dispatch(
      authorizationLayer({
        url: `processes/tasks/next-step`,
        method: 'post',
        data: {
          taskId: id,
          stageId: nextStageId,
          moduleId: nextStageModuleId,
        },
      })
    ).then(({ data }) => {
      if (data) {
        setCurrentStageData(data.data.currentStage)
        setIsModalOpen(false)
        setTaskData(data.data)
        dispatch(SET_APP(['dataSpareTask'], []))
        dispatch(SET_APP(['dataSpareNeeds'], []))
      }
    })
  }

  const endProcess = async () => {
    setDisabledBtn(true)
    const isValid = await validateModules()
    if (_.isString(isValid)) {
      setDisabledBtn(false)

      return notification.warn({
        message: t('task.footer.warnMessage'),
        description: isValid,
      })
    }
    await dispatch(
      authorizationLayer({
        url: `processes/tasks/${id}/complete`,
      })
    ).then((res) => {
      if (res.status === 200) {
        navigate('/')
      }
      dispatch(SET_APP(['dataSpareTask'], []))
      dispatch(SET_APP(['dataSpareNeeds'], []))
    })

    setDisabledBtn(false)
  }

  useEffect(() => {
    let nextStageId = ''
    let nextStageModuleId = ''

    const nextStageIndex = taskData?.process?.stages?.findIndex(
      (el) => el.id === taskData?.currentStage?.id
    )

    nextStageId = taskData?.process?.stages?.[nextStageIndex + 1]?.id
    nextStageModuleId =
      taskData?.process?.stages?.[nextStageIndex + 1]?.modules?.[0]?.id

    setNextStageId(nextStageId)
    setNextStageModuleId(nextStageModuleId)

    if (
      taskData?.currentStage?.id ===
      taskData?.process?.stages?.[taskData?.process?.stages?.length - 1].id
    ) {
      setReplaceToEndStatus(true)
    } else {
      setReplaceToEndStatus(false)
    }
  }, [taskData])

  return (
    <div className='footer'>
      <Row justify='space-between' align='middle'>
        <Col>
          <Space size={10}>
            <Button danger shape='round' onClick={showModalCancel}>
              {t('request.common.button.reject')}
            </Button>
            <Button
              shape='round'
              onClick={showModalPaused}
              className='footer__paused-btn'
            >
              {t('request.common.button.postpone')}
            </Button>
          </Space>
        </Col>
        <Col>
          {!loading ? (
            <Button
              shape='round'
              type='primary'
              onClick={!replaceToEndStatus ? nextStage : endProcess}
              disabled={!replaceToEndStatus ? false : disabledBtn}
            >
              {!replaceToEndStatus
                ? t('task.footer.nextStage')
                : t('task.footer.endProcess')}
            </Button>
          ) : (
            <Spin indicator={<LoadingOutlined />} style={{ marginRight: 40 }} />
          )}
        </Col>
      </Row>
      <Modal
        // visible={visible}
        open={visible}
        style={{ top: 80 }}
        title={
          currentModal === 1
            ? t('task.footer.chooseRefusal')
            : t('task.footer.taskPostpone')
        }
        width={600}
        okText={t('task.footer.modal.OkText')}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: '24px 40px 48px 40px' }}
        onOk={() => funcToPost()}
        okButtonProps={{
          disabled: btnStatus,
          shape: 'round',
          loading: loadingBtn,
        }}
        cancelButtonProps={{
          shape: 'round',
        }}
      >
        {currentModal === 1 ? (
          <CancelModal
            onChangeRadio={onChangeRadio}
            cancelReasonValue={cancelReasonValue}
            cancelReasons={cancelReasons}
            onChangeTextArea={onChangeTextArea}
            textAreaStatus={textAreaStatus}
          />
        ) : (
          <PausedModal form={form} disabledDate={disabledDate} />
        )}
      </Modal>
      <Modal
        maskClosable={false}
        closable={false}
        open={isModalOpen}
        footer={null}
        className='modal-stages'
        width={264}
        style={{
          top: 40,
        }}
      >
        <div className='modal-stages__wrap'>
          <Spin
            className='modal-stages__spin'
            indicator={<LoadingOutlined />}
            style={{ marginRight: 8 }}
          />
          <span>{t('request.common.transitionToStage')}...</span>
        </div>
      </Modal>
    </div>
  )
}

export default Footer
