import React from 'react'
import { Input, DatePicker, Form } from 'antd'
import './index.less'
import { useTranslation } from 'react-i18next'
const { TextArea } = Input

const PausedModal = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <>
      <Form
        form={props.form}
        layout='vertical'
        autoComplete='off'
        style={{ maxWidth: 180 }}
      >
        <Form.Item
          name='date'
          label={t('task.footer.pausedModal.changeDateTime')}
          rules={[
            {
              required: true,
              message: t('task.footer.pausedModal.changeDateTime'),
            },
          ]}
        >
          <DatePicker
            disabledDate={props.disabledDate}
            format='DD.MM.YYYY'
            style={{ minHeight: 32, width: '100%' }}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          name='comments'
          label={t('task.actionHistory.modal.warehouse.comment')}
          style={{ marginTop: 24, minWidth: 520 }}
          rules={[
            {
              required: true,
              message: t('task.footer.pausedModal.commentRequired'),
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={150}
            style={{ width: '100%' }}
            placeholder={t('task.cancelModal.textAreaPlaceholder')}
            className='text-area'
          />
        </Form.Item>
      </Form>
    </>
  )
}

export default PausedModal
