import React from 'react'
import _ from 'lodash'
import { Typography, Row, Col, Space, Card } from 'antd'
import { Tabs, Image, Skeleton } from 'antd'
import { numberWithSpaces, onCloseModal } from '@utils'
import { useTranslation } from 'react-i18next'

const { Title, Text } = Typography

const { TabPane } = Tabs

const CarsDetails = (props) => {
  const {
    loading,
    productData,
    tabsComponents,
    mainImg,
    photosData,
    setVisible,
    visible,
    activePhoto,
    currentImage,
  } = props
  const { t } = useTranslation()
  return (
    <Card bodyStyle={{ padding: '40px 80px 104px' }}>
      <Row justify='space-between' wrap={false}>
        {!loading ? (
          <Col flex='600px'>
            <Space direction='vertical' size={8}>
              <Title level={3}>
                {productData?.carMark?.name} {productData?.carModel?.name}
              </Title>
              <Title level={3}>
                {productData?.price
                  ? `${numberWithSpaces(productData?.price)}`
                  : null}
              </Title>
              {productData?.carTemplate !== null ? (
                <Text>
                  {t('productDetails.cars.equipment')}:{' '}
                  {<Text strong>{productData?.carTemplate?.name}</Text>}
                </Text>
              ) : (
                ''
              )}
              <Text>
                {productData?.status === 'in_stock' && (
                  <span style={{ color: '#3BD378' }}>
                    {t('productDetails.cars.inStock')}
                  </span>
                )}
                {productData?.status === 'in_transit' && (
                  <span style={{ color: '#FF9900' }}>
                    {t('productDetails.cars.inTransit')}
                  </span>
                )}
                {productData?.status === 'in_production' && (
                  <span style={{ color: ' rgba(41, 52, 82, 0.4)' }}>
                    {t('productDetails.cars.inProduction')}
                  </span>
                )}
              </Text>
              {productData?.conditions?.length ? (
                <div
                  style={{
                    padding: '8px 24px',
                    background: '#F0F2F4',
                    borderRadius: 6,
                  }}
                >
                  <Space direction='vertical' size={4}>
                    <Text>
                      {productData?.conditions.map((item, index) => {
                        return <span key={index}>{item}</span>
                      })}
                    </Text>
                  </Space>
                </div>
              ) : (
                ''
              )}
            </Space>
            <Tabs defaultActiveKey='1' style={{ marginTop: 28 }}>
              {tabsComponents.map((item) => {
                return (
                  <TabPane key={item.id} tab={item.title}>
                    <div style={{ marginTop: 16 }}>{item.content}</div>
                  </TabPane>
                )
              })}
            </Tabs>
          </Col>
        ) : (
          <Space direction='vertical' size={41} style={{ width: '50%' }}>
            <Skeleton active paragraph={{ rows: 1 }} />
            <Space size={20}>
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
              <Skeleton.Button
                active
                shape='round'
                size='middle'
                style={{ width: 100 }}
              />
            </Space>
            <Skeleton active />
          </Space>
        )}
        {!loading ? (
          <Col flex='600px'>
            <div
              style={{
                width: 600,
                height: 316,
              }}
            >
              {mainImg}
            </div>
            <div className='images-list'>
              {photosData?.carPhotos
                ?.map((item, index) => {
                  return (
                    <div
                      className={
                        activePhoto === index
                          ? 'images-item active'
                          : 'images-item'
                      }
                      key={item.id}
                      onClick={() => currentImage(item.id)}
                    >
                      <img
                        key={item.id}
                        src={item.photo}
                        alt='car'
                        width='100%'
                      />
                    </div>
                  )
                })
                .slice(0, 5)}
              {photosData?.carPhotos?.length > 5 ? (
                <div
                  className='images-gallery'
                  onClick={() => setVisible(true)}
                >
                  <Text
                    className='images-gallery__text'
                    onClick={() => setVisible(true)}
                  >
                    +{photosData?.carPhotos.length - 5} фото
                  </Text>
                  <Image.PreviewGroup
                    preview={{
                      visible,
                      onVisibleChange: (value) => {
                        setVisible(value)
                      },
                    }}
                  >
                    {photosData?.carPhotos?.map((item) => {
                      return <Image key={item.id} src={item.photo} />
                    })}
                  </Image.PreviewGroup>
                  <div
                    className='images-gallery__bg'
                    onClick={() => setVisible(true)}
                  ></div>
                </div>
              ) : (
                ''
              )}
            </div>
          </Col>
        ) : (
          <Space direction='vertical' size={40}>
            <Skeleton.Image active style={{ width: 600, height: 316 }} />
          </Space>
        )}
      </Row>
    </Card>
  )
}

export default CarsDetails
