import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { useDispatch } from 'react-redux'

const useCarsColors = () => {
  const dispatch = useDispatch()

  const getExterior = async () => {
    await dispatch(
      authorizationLayer({
        url: `wh-cars/references/car_exterior_colors`,
      })
    ).then(({ data }) => {
      dispatch(SET_APP(['exteriorColors'], data))
    })
  }

  const getInterior = async () => {
    await dispatch(
      authorizationLayer({
        url: 'wh-cars/references/car_interior_colors',
      })
    ).then(({ data }) => {
      dispatch(SET_APP(['interiorColors'], data))
    })
  }
  return {
    getExterior,
    getInterior,
  }
}

export default useCarsColors
