import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import Loading from '@ui/Loading'
import ProductDetails from '@modals/product_details'
import useNotification from '@hooks/useNotification'
import { YMInitializer } from 'react-yandex-metrika'
import Routers from './router/'
import 'antd/dist/antd.less'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'
import enUS from 'antd/es/locale/en_US'
import i18n from './i18n'
import moment from 'moment'
import 'moment/locale/ru'

export const AppContext = React.createContext()
// moment.locale(i18n.language)

const App = (props) => {
  const dispatch = useDispatch()
  const [_user, _setUser] = useState(null)
  const loader = useSelector((state) => state.app.loader)
  const modals = useSelector((state) => state.app.modals)
  const user = useSelector((state) => state.app.user)
  const token = useSelector((state) => state.app.token)
  const [mode, setMode] = useState('desktop')
  const notification = useNotification()
  const [codeYM, setCodeYM] = useState(null)
  const [antdProviderLang, setAntdProviderLang] = useState(enUS)
  useEffect(() => {
    if (user) {
      if (user?.yandex_metrics_code) {
        setCodeYM(user.yandex_metrics_code)
      }
      if (!_user || _user.id !== user.id) {
        _setUser(user)
      }
    } else {
      _setUser(null)
    }
  }, [user])

  const getTranslate = async () => {
    await dispatch(
      authorizationLayer({
        url: 'translations',
      })
    ).then(({ data }) => {
      if (data.success) {
        i18n.addResourceBundle(
          i18n.language,
          'translation',
          data.data,
          true,
          true
        )
      }
    })
  }

  useEffect(() => {
    if (_user) {
      const getData = async () => {
        dispatch(SET_APP(['loader'], true))

        try {
          const { data } = await dispatch(
            authorizationLayer({
              url: `dealers/info`,
            })
          )

          dispatch(SET_APP(['permissions'], data.permissions))

          if (_user.locale) {
            const langs = [ruRU, enUS]
            const lang = langs.find((i) => i.locale === _user.locale)

            if (lang) setAntdProviderLang(lang)
            await i18n.changeLanguage(_user.locale)
            moment.locale(_user.locale)
          }

          await getTranslate()
        } catch (e) {
          console.log(e.message)
        } finally {
          dispatch(SET_APP(['loader'], false))
        }
      }
      getData()
    }
  }, [_user])

  useEffect(() => {
    dispatch(SET_APP(['modals'], []))
  }, [])

  return (
    <ConfigProvider locale={antdProviderLang}>
      <React.Suspense fallback={<Loading />}>
        {codeYM !== null ? (
          <YMInitializer
            accounts={[codeYM]}
            options={{ webvisor: true }}
            version='2'
          />
        ) : null}
        {modals?.map((modal) => {
          if (modal.type === 'product-details') {
            return (
              <ProductDetails
                key={modal.id}
                id={modal.id}
                spareStatus={modal.spareStatus}
                stockType={modal.stockType}
              />
            )
          }
        })}
        {loader ? <Loading /> : ''}
        <AppContext.Provider
          value={{ mode, setMode, setAntdProviderLang, ruRU, enUS }}
        >
          <Routers notification={notification} />
        </AppContext.Provider>
      </React.Suspense>
    </ConfigProvider>
  )
}
export default App
