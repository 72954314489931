import { Tooltip, Typography, Button, Space } from 'antd'
import moment from 'moment'
import { numberWithSpaces, openModals } from '@utils'
import _ from 'lodash'
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { t } from 'i18next'

const { Text } = Typography

export const autoColumns = (dispatch, stockType, tableSettings) => {
  let data = [
    {
      title: t('warehouse.tab.cars.column.brand'),
      dataIndex: 'carMark',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carMark?.name ? record?.carMark?.name : null}
        >
          {record?.carMark?.name ? record?.carMark?.name : null}
        </Tooltip>
      ),
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.model'),
      dataIndex: 'carModel',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carModel?.name ? record?.carModel?.name : null}
        >
          {record?.carModel?.name ? record?.carModel?.name : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.equipment'),
          dataIndex: 'carTemplate',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={
                record?.carTemplate?.name ? record?.carTemplate?.name : null
              }
            >
              {record?.carTemplate?.name ? record?.carTemplate?.name : null}
            </Tooltip>
          ),
          width: 200,
          ellipsis: {
            showTitle: false,
          },
        }
      : {},
    {
      title: t('warehouse.tab.cars.column.vin'),
      dataIndex: 'vin_code',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 180,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.bodyColor'),
      dataIndex: 'carExteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carExteriorColor?.name
              ? record?.carExteriorColor?.name
              : null
          }
        >
          {record?.carExteriorColor?.name
            ? record?.carExteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.interiorColor'),
      dataIndex: 'carInteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carInteriorColor?.name
              ? record?.carInteriorColor?.name
              : null
          }
        >
          {record?.carInteriorColor?.name
            ? record?.carInteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.year'),
      dataIndex: 'year',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.status'),
          dataIndex: 'status',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={() => {
                if (text === 'in_stock') {
                  return t('warehouse.tab.cars.column.inStockStatus')
                } else if (text === 'in_transit') {
                  return t('warehouse.tab.cars.column.inTransitStatus')
                } else if (text === 'in_production') {
                  return t('warehouse.tab.cars.column.inProductionStatus')
                }
              }}
            >
              {text === 'in_stock'
                ? t('warehouse.tab.cars.column.inStockStatus')
                : ''}
              {text === 'in_transit'
                ? t('warehouse.tab.cars.column.inTransitStatus')
                : ''}
              {text === 'in_production'
                ? t('warehouse.tab.cars.column.inProductionStatus')
                : ''}
            </Tooltip>
          ),
          width: 140,
          ellipsis: {
            showTitle: false,
          },
        }
      : {},
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.arrivalDate'),
          dataIndex: 'stock_date',
          render: (text) => (
            <Tooltip
              placement='topLeft'
              title={text ? moment(text).format('DD MMMM YYYY') : null}
            >
              {text ? moment(text).format('DD MMMM YYYY') : null}
            </Tooltip>
          ),
          width: 165,
          ellipsis: {
            showTitle: false,
          },
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.responsiblePerson'),
      dataIndex: 'user',
      width: 200,

      render: (text, record) => (
        <Tooltip placement='topLeft' title={record?.userName}>
          {record?.userName}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: t('warehouse.tab.cars.column.client'),
      dataIndex: 'client',
      width: 150,
      render: (text, record) => (
        <Tooltip placement='topLeft' title={record?.clientName}>
          {record?.clientName}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.terms'),
          dataIndex: 'conditions',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={
                record?.conditions !== null
                  ? record?.conditions?.map((item, index) => {
                      return <p key={index}>{item}</p>
                    })
                  : ''
              }
            >
              {record?.conditions !== null
                ? record?.conditions?.map((item, index) => {
                    return <Text key={index}>{item}</Text>
                  })
                : ''}
            </Tooltip>
          ),
          width: 140,
          ellipsis: {
            showTitle: false,
          },
        }
      : {},

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.mileage'),
          dataIndex: 'mileage',
          render: (text) => (
            <Tooltip placement='topLeft' title={`${numberWithSpaces(text)} `}>
              {`${numberWithSpaces(text)} `}
            </Tooltip>
          ),
          width: 140,
          ellipsis: {
            showTitle: false,
          },
        }
      : {},

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.price'),
          dataIndex: 'price',
          render: (text) => (
            <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
              {`${numberWithSpaces(text)}`}
            </Tooltip>
          ),
          width: 160,
          ellipsis: {
            showTitle: false,
          },
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.engine'),
      dataIndex: 'engine_capacity',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 160,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.gearboxType'),
      dataIndex: 'carGearboxType',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carGearboxType?.short_value
              ? record?.carGearboxType?.short_value
              : null
          }
        >
          {record?.carGearboxType?.short_value
            ? record?.carGearboxType?.short_value
            : null}
        </Tooltip>
      ),
      width: 120,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: stockType === 'spare_parts' ? true : false,
            })
          }
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
      fixed: 'right',
    },
  ]

  data = data.filter(
    (item) =>
      tableSettings?.includes(item.dataIndex) || item.dataIndex === 'action'
  )

  return data
}

export const columnsForSpareParts = (dispatch, stockType, tableSettings) => {
  let data = [
    {
      title: '',
      dataIndex: 'count',
      render: (text, record, index) => {
        return (
          <Space size={16}>
            <Button type='link' disabled={true}>
              <MinusSquareOutlined style={{ fontSize: 20 }} />
            </Button>
            <Text type='secondary' style={{ whiteSpace: 'nowrap' }}>
              {record.orderAmount}
            </Text>
            <Button type='link' disabled={true}>
              <PlusSquareOutlined style={{ fontSize: 20 }} />
            </Button>
          </Space>
        )
      },
      width: 120,
    },
    {
      title: t('warehouse.tab.parts.column.category'),
      dataIndex: 'sparePartCategory',
      width: 220,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.sparePartCategory?.name
              ? record?.sparePartCategory?.name
              : null
          }
        >
          {record?.sparePartCategory?.name
            ? record?.sparePartCategory?.name
            : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.name'),
      dataIndex: 'name',
      width: 280,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.article'),
      dataIndex: 'article_number',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.inStock'),
      dataIndex: 'amount',
      width: 160,
      render: (text, record) => {
        return (
          <Tooltip placement='topLeft' title={`${numberWithSpaces(text)} `}>
            {`${numberWithSpaces(text)} `}
          </Tooltip>
        )
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.reserve'),
      dataIndex: 'reserve',
      width: 160,
      render: (text, record) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)} `}>
          {`${numberWithSpaces(text)} `}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.price'),
      dataIndex: 'price',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
          {`${numberWithSpaces(text)}`}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          onClick={() =>
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: stockType === 'spare_parts' ? true : false,
            })
          }
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
    },
  ]

  data = data.filter(
    (item) =>
      tableSettings?.includes(item.dataIndex) ||
      item.dataIndex === 'action' ||
      item.dataIndex === 'count'
  )

  return data
}

const Counter = ({ ...props }) => {
  return (
    <Space size={16}>
      <Button type='primary' className='counter-btn' ghost disabled={true}>
        <svg
          width='8'
          height='2'
          viewBox='0 0 8 2'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M-5.96046e-08 1H8'
            stroke='rgba(0, 0, 0, 0.25)'
            strokeWidth='2'
          />
        </svg>
      </Button>
      <Text type='secondary'>{props.data.orderAmount}</Text>
      <Button
        type='primary'
        className='counter-btn counter-btn--focus'
        ghost
        disabled={true}
      >
        +
      </Button>
    </Space>
  )
}
