import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import { Input, Select, DatePicker, TimePicker, InputNumber } from 'antd'
import moment from 'moment'
import { Form } from 'antd'
import _ from 'lodash'
import './index.less'
import { ModuleContext } from '../../..'
import { forwardRef, useImperativeHandle } from 'react'
import { useCallback } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input
const { Option } = Select
const formatTime = 'HH:mm'
// const formatDate = 'YYYY-MM-DD'
// const formatDate = 'DD-MM-YYYY'

const Forms = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { module, taskData, currentNeeds, cardTitle } =
    useContext(ModuleContext)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState([])
  const [fields, setFields] = useState([])

  const [form] = Form.useForm()

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        try {
          const values = await form.validateFields()
          return values
        } catch (e) {
          return null
        }
      },
    }),
    []
  )

  useEffect(() => {
    const getFormsSettings = async () => {
      await dispatch(
        authorizationLayer({
          url: `forms/${taskData?.process?.id}/${module?.id}`,
        })
      ).then(({ data }) => {
        setFormData(data)
        setFields(currentNeeds?.meta || [])

        let formObj = {}

        currentNeeds?.meta?.forEach((i) => {
          if (i.type === 'multi_select') {
            formObj[i.id] = i.fieldDataList || i.fieldData
          } else if (i.type === 'data') {
            formObj[i.id] = i.fieldData ? moment(i.fieldData) : undefined
          } else if (i.type === 'time') {
            let newDate = moment().format('YYYY-MM-DD')

            formObj[i.id] = i.fieldData
              ? moment(newDate + ' ' + i.fieldData + ':00')
              : undefined
          } else formObj[i.id] = i.fieldData
        })

        form.setFieldsValue(formObj)
      })
    }
    getFormsSettings()
  }, [])

  const onSubmit = useCallback(
    _.debounce(async (fields) => {
      await dispatch(
        authorizationLayer({
          url: `processes/tasks/${taskData?.id}/${module?.id}/needs`,
          method: 'POST',
          data: [
            {
              type: 'form',
              action: 'choice',
              meta: fields,
            },
          ],
        })
      )
    }, 500),
    []
  )

  const onChange = (value, id, type, fieldName) => {
    let index = _.findIndex(fields, (i) => i.id === id)

    if (index !== -1) {
      fields[index] = {
        id: id,
        fieldData: value,
        type: type,
        name: fieldName,
      }

      setFields([...fields])
      onSubmit([...fields])
    } else {
      setFields([
        ...fields,
        {
          id: id,
          fieldData: value,
          type: type,
          name: fieldName,
        },
      ])
      onSubmit([
        ...fields,
        {
          id: id,
          fieldData: value,
          type: type,
          name: fieldName,
        },
      ])
    }
  }

  return (
    <>
      {cardTitle}
      <Form
        form={form}
        autoComplete='off'
        layout='vertical'
        name='form'
        className='forms__wrap'
      >
        {formData.fields?.map((field) => {
          let result = ''
          let fieldLists = []

          if (field.fieldLists?.length > 0) {
            fieldLists = field.fieldLists?.map((item) => (
              <Option key={item.id} value={item.value} label={item.value}>
                {item.value}
              </Option>
            ))
          }

          switch (field.type) {
            case 'string':
              result = (
                <Input
                  onChange={(e) =>
                    onChange(e.target.value, field.id, field.type, field.name)
                  }
                />
              )
              break
            case 'paragraph':
              result = (
                <TextArea
                  rows={3}
                  onChange={(e) =>
                    onChange(e.target.value, field.id, field.type, field.name)
                  }
                />
              )
              break
            case 'select':
              result = (
                <Select
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  onChange={(e) =>
                    onChange(e, field.id, field.type, field.name)
                  }
                >
                  {fieldLists}
                </Select>
              )
              break
            case 'multi_select':
              result = (
                <Select
                  mode='multiple'
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  optionLabelProp='label'
                  onChange={(e) =>
                    onChange(e, field.id, field.type, field.name)
                  }
                >
                  {fieldLists}
                </Select>
              )
              break
            case 'data':
              result = (
                <DatePicker
                  style={{ width: 128, height: 30 }}
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  format='DD-MM-YYYY'
                  onChange={(e) =>
                    onChange(
                      e ? moment(e).format('YYYY-MM-DD').toString() : null,
                      field.id,
                      field.type,
                      field.name
                    )
                  }
                />
              )
              break
            case 'time':
              result = (
                <TimePicker
                  style={{ width: 128, height: 30 }}
                  placeholder={t(
                    'task.moduleRender.module.carReception.checkList.selectPlaceholder'
                  )}
                  format='HH:mm'
                  popupClassName='timepicker'
                  onSelect={(e) => {
                    let obj = {}
                    fields?.forEach((i) => {
                      if (i.id === field.id) {
                        obj[i.id] = e
                      }
                    })

                    form.setFieldsValue(obj)

                    onChange(
                      e ? moment(e).format(formatTime).toString() : null,
                      field.id,
                      field.type,
                      field.name
                    )
                  }}
                />
              )
              break
            case 'number':
              result = (
                <InputNumber
                  style={{ width: 320, height: 30 }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                  onChange={(e) =>
                    onChange(e, field.id, field.type, field.name)
                  }
                />
              )
          }

          return (
            <React.Fragment key={field.id}>
              <Form.Item
                style={{
                  width: '320px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#293452',
                }}
                label={field.name}
                name={field.id}
                rules={[
                  {
                    required: module?.isRequired,
                    message: t('common.input.requiredMessage'),
                  },
                ]}
              >
                {result}
              </Form.Item>
            </React.Fragment>
          )
        })}
      </Form>
    </>
  )
})

export default Forms
