import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  authorizationLayer,
  SET_APP,
  UNSHIFT_APP,
  SET_APP_BY_PARAM,
} from '@store/actions/methods'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SSE } from '@utils'
import {
  Badge,
  Checkbox,
  Empty,
  Popover,
  Space,
} from 'antd'
import { Input, Modal, notification } from 'antd'
import { SendOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Form, Menu } from 'antd'
import Loading from '@ui/Loading'
import './index.less'
import SwitchUserFromTask from './SwitchUserFromTask'
import HistoryModal from './Modals'
import Comment from './Comment'
import queryString from 'query-string'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

const ClientHistory = ({ data }) => {
  const { t } = useTranslation()
  const { taskData } = data
  const dispatch = useDispatch()
  const [formForComments] = Form.useForm()
  const [formForCommentsCoordination] = Form.useForm()
  const { user } = useSelector((state) => state.app)
  const comments = useSelector((state) => state.app.comments)
  const [commentModal, setCommentModal] = useState('')
  const [open, setOpen] = useState(false)
  const [openModalComment, setOpenModalComment] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [coordination, setCoordination] = useState(true)
  const [nameModal, setNameModal] = useState('')
  const [infoModal, setInfoModal] = useState('')
  const [userModal, setUserModal] = useState('')
  const [bodyModal, setBodyModal] = useState({})
  const [switchData, setSwitchData] = useState({})
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [dataIn, setDataIn] = useState([])

  const [infinitydata, setInfinitydata] = useState([])

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })

  const [filters, setFilters] = useState([])

  const comment = Form.useWatch('comment', formForComments)
  const commentCoordination = Form.useWatch(
    'commentCoordination',
    formForCommentsCoordination
  )

  const showModal = async (eventId, eventName, eventGroup, user) => {
    if (eventName === 'module_upload') {
      setNameModal(t('task.actionHistory.module.file'))
    } else if (
      eventName === 'module_cars' ||
      eventName === 'module_test_drive' ||
      eventName === 'module_parts'
    ) {
      setNameModal(t('task.actionHistory.module.need'))
    } else if (eventName === 'module_form') {
      setNameModal(t('task.actionHistory.module.form'))
    } else if (eventName === 'warehouse_cars') {
      setNameModal(t('task.actionHistory.module.characteristicsCar'))
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'checklist'
    ) {
      setNameModal(t('task.actionHistory.module.options'))
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'quality_control'
    ) {
      setNameModal(t('task.actionHistory.module.testDrive'))
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'external'
    ) {
      setNameModal(t('task.actionHistory.module.exteriorInspection'))
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'internal'
    ) {
      setNameModal(t('task.actionHistory.module.interiorInspection'))
    }
    setInfoModal(taskData.process.name)
    setUserModal(user.full_name)

    await dispatch(
      authorizationLayer({
        url: `action_history/event/${eventId}`,
        method: 'get',
      })
    ).then((res) => {
      if (res.data) {
        setBodyModal(res.data)
        setOpen(true)
      }
    })
  }

  const showModalComment = (info) => {
    setOpenModalComment(true)
    setCommentModal(info)
  }

  const showDrawer = async (item) => {
    await dispatch(
      authorizationLayer({
        url: `action_history/event/${item?.event_id}`,
        method: 'get',
      })
    ).then((res) => {
      if (res.data) {
        setSwitchData(res.data?.event_value)
        setOpenDrawer(true)
      }
    })
  }

  const cancelDrawer = () => {
    setOpenDrawer(false)
  }

  const handleCancelComment = () => {
    setOpenModalComment(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const sendComments = async () => {
    if (comment.trim() !== undefined && comment.trim() !== '') {
      setLoading(true)
      await dispatch(
        authorizationLayer({
          url: `action_history/event`,
          method: 'post',
          data: {
            event_task_id: taskData?.id || '',
            event_user_id: user.id || '',
            event_name: 'task',
            event_group: 'comment',
            event_type: 'create',
            data: {
              comment: comment.trim() || '',
              user: {
                id: user.id || '',
                full_name: user.full_name || '',
              },
            },
          },
        })
      ).then(() => {
        setLoading(false)
        formForComments.resetFields(['comment'])
      })
    }
  }

  const formatHTML = (item, type) => {
    if (Object.keys(item).length !== 0) {
      let result
      let newStr = item.event

      result = (
        <span
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
          dangerouslySetInnerHTML={{ __html: newStr }}
        />
      )

      return result
    }
  }

  const getData = async () => {
    try {
      setLoading(true)

      let q = {
        page: pagination.current,
        limit: pagination.pageSize,
        filters: filters,
      }

      const query = queryString.stringify(q, { arrayFormat: 'comma' })

      const {
        data: { data: items, meta },
      } = await dispatch(
        authorizationLayer({
          url: `action_history/tasks?task_id=${taskData?.mainTaskId}&${query}`,
          method: 'get',
        })
      )
      if (items?.length < 10) setHasMore(false)
      else if (meta?.total <= 10) setHasMore(false)
      else setHasMore(true)

      if (items?.length) {
        if (pagination.current !== 1)
          dispatch(SET_APP(['comments'], [...comments, ...items]))
        else dispatch(SET_APP(['comments'], items))
      } else {
        dispatch(SET_APP(['comments'], []))
      }

      setLoading(false)
    } catch (e) {
      console.log(e.message)
    }
  }

  const coordinationTask = async (action) => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `processes/tasks/${taskData?.id}/needs`,
        method: 'GET',
      })
    ).then(async ({ data }) => {
      if (data.data.needs?.length > 0) {
        let coordinationNeeds = false
        data.data.needs?.forEach((item) => {
          if (item.type === 'Coordination') coordinationNeeds = true
        })

        if (coordinationNeeds) {
          if (action === true) {
            dispatch(
              authorizationLayer({
                url: `processes/tasks/change-coordination-status/${taskData?.id}`,
                method: 'PATCH',
                data: {
                  coordinationStatus: action,
                },
              })
            ).then((res) => {
              if (res.data?.success) {
                if (
                  res.data?.data?.code === 'COMPLETED' ||
                  res.data?.data?.code === 'CANCELLED'
                ) {
                  notification.error({
                    message: t('task.actionHistory.module.errorMessage'),
                  })
                }
              }
            })
          } else {
            dispatch(
              authorizationLayer({
                url: `processes/tasks/change-coordination-status/${taskData?.id}`,
                method: 'PATCH',
                data: {
                  coordinationStatus: action,
                  cancelComment:
                    commentCoordination ||
                    t('task.actionHistory.module.refusal'),
                },
              })
            ).then((res) => {
              if (res.data?.success) {
                if (
                  res.data?.data?.code === 'COMPLETED' ||
                  res.data?.data?.code === 'CANCELLED'
                ) {
                  notification.error({
                    message: t('task.actionHistory.module.errorMessage'),
                  })
                }
              }
            })
          }
        }

        if (
          commentCoordination !== undefined &&
          commentCoordination?.trim() !== ''
        ) {
          dispatch(
            authorizationLayer({
              url: `action_history/event`,
              method: 'post',

              data: {
                event_task_id: taskData?.id || '',
                event_user_id: user.id || '',
                event_name: 'task',
                event_group: 'comment',
                event_type: 'create',
                data: {
                  comment: commentCoordination || '',
                  user: {
                    id: user.id || '',
                    full_name: user.full_name || '',
                  },
                },
              },
            })
          )
        }
      }
    })
    setCoordination(false)
    setLoading(false)
    formForCommentsCoordination.resetFields(['commentCoordination'])
  }

  const _handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (!e.shiftKey) sendComments()
    }
  }

  const deleteComment = async (id) => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `action_history/event/${id}`,
        method: 'DELETE',
      })
    )
      .then(() => {
        const newcomment = comments.filter((i) => i.event_id !== id)
        dispatch(SET_APP(['comments'], newcomment))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const actionDropdownMenu = (id) => {
    return (
      <Menu
        items={[
          {
            label: (
              <span
                onClick={() => deleteComment(id)}
                style={{ width: '100%', height: '100%' }}
              >
                {t('task.actionHistory.module.deleteBtn')}
              </span>
            ),
            key: 1,
          },
        ]}
      />
    )
  }

  useEffect(() => {
    if (taskData) getData()
  }, [pagination])

  useEffect(() => {
    dispatch(SET_APP(['comments'], []))
  }, [])

  const HISTORY_CREATED = `HISTORY_CREATED_${taskData?.mainTaskId}`
  const HISTORY_UPDATED = `HISTORY_UPDATED_${taskData?.mainTaskId}`

  const onCreatedHistory = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event
    dispatch(UNSHIFT_APP(['comments'], data))
  }

  const onUpdatedHistory = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event

    dispatch(SET_APP_BY_PARAM(['comments'], ['event_id', data?.event_id], data))
  }

  const sse = useRef()

  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(HISTORY_CREATED, onCreatedHistory)
      sse.current.addEventListener(HISTORY_UPDATED, onUpdatedHistory)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(HISTORY_CREATED, onCreatedHistory)
        sse.current.removeEventListener(HISTORY_UPDATED, onUpdatedHistory)
      }
    }
  }, [])

  const footerChange = (item) => {
    return (
      <div
        className='history-comment__info-interaction'
        onClick={() => showDrawer(item)}
      >
        {t('task.actionHistory.module.changeBtn')}
      </div>
    )
  }
  const renderFooterChange = (item) => {
    if (
      item.event_name === 'task' ||
      item.event_name === 'module_interaction'
    ) {
      if (
        (item.event_group === 'default' ||
          item.event_group === 'change_user') &&
        item.event_type === 'create' &&
        item.event_task_id === taskData?.id &&
        (taskData?.userId === user.id || taskData?.userCreatedId === user.id) &&
        taskData?.status?.code !== 'COMPLETED' &&
        taskData?.status?.code !== 'CANCELLED'
      ) {
        return footerChange(item)
      }
    }
    return null
  }

  const height = () => {
    const windowHeight = document.body.offsetHeight
    const headerHeight =
      taskData?.coordinationUsers?.includes(user.id) &&
      (taskData?.status.code === 'ACTIVE' || taskData?.status.code === 'NEW') &&
      coordination
        ? windowHeight - 590
        : windowHeight - 430

    return headerHeight
  }

  const filtersData = [
    { label: t('task.actionHistory.commentType.needSelect'), value: 'needs' },
    {
      label: t('task.actionHistory.commentType.carReception'),
      value: 'reception',
    },
    { label: t('task.actionHistory.commentType.file'), value: 'moduleUpload' },
    { label: t('task.actionHistory.commentType.forms'), value: 'moduleForm' },
    {
      label: t('task.actionHistory.commentType.interectionWithDepartments'),
      value: 'moduleInteraction',
    },
    {
      label: t('task.actionHistory.commentType.agreement'),
      value: 'coordination',
    },
    { label: t('task.actionHistory.commentType.comment'), value: 'comment' },
  ]

  return (
    <div className='task__history'>
      {loading && <Loading spinner={true} />}
      <div className='task__history-box'>
        <div className='task__history-box task__history-box--inner'>
          <p className='task__p task__history-text'>
            {t('clientCard.history.actionHistory')}{' '}
          </p>
          <p className='task__p task__history-id'>id: {taskData?.inner_id}</p>
        </div>
        <Badge count={filters?.length} color='#1890FF'>
          <Popover
            trigger='click'
            content={
              <>
                <Space direction='vertical' size={12}>
                  <Checkbox.Group
                    options={filtersData}
                    onChange={(e) => {
                      setFilters(e)
                      setPagination({
                        current: 1,
                        pageSize: 10,
                      })
                    }}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  />
                </Space>
              </>
            }
            placement='bottomLeft'
          >
            <Button type='link'>
              {t('task.actionHistory.module.filter')} <FilterOutlined />
            </Button>
          </Popover>
        </Badge>
      </div>

      <Form
        form={formForComments}
        autoComplete='off'
        className='task__history-form'
      >
        <Form.Item name='comment' style={{ margin: 0, marginRight: '-1px' }}>
          <TextArea
            rows={1}
            autoSize={{ minRows: 1, maxRows: 5 }}
            placeholder={t('task.actionHistory.module.commentPlaceholder')}
            className='task__history-text-area'
            style={{
              width: '350px',
              height: '32px',
              borderRadius: '6px',
              marginRight: 8,
            }}
            onKeyDown={(e) => _handleKeyDown(e)}
          />
        </Form.Item>
        <div className='task__history-send'>
          <SendOutlined
            style={{ fontSize: 24 }}
            onClick={() => sendComments()}
          />
        </div>
      </Form>
      <ul style={{ margin: '0', padding: '0' }}>
        {comments?.length ? (
          <InfiniteScroll
            dataLength={comments?.length}
            className='scrollableDiv'
            next={() => {
              setPagination((value) => ({
                ...value,
                current: value.current + 1,
              }))
            }}
            hasMore={hasMore}
            height={height()}
          >
            {comments &&
              comments?.map((item, index) => (
                <li
                  key={index}
                  className='task__history-comment history-comment'
                >
                  <Comment
                    item={item}
                    user={user}
                    setLoading={setLoading}
                    taskData={taskData}
                    setSwitchData={setSwitchData}
                    setOpenDrawer={setOpenDrawer}
                    setNameModal={setNameModal}
                    setInfoModal={setInfoModal}
                    setUserModal={setUserModal}
                    setBodyModal={setBodyModal}
                    setOpen={setOpen}
                  />
                </li>
              ))}
          </InfiniteScroll>
        ) : null}
        {comments?.length === 0 && !loading && <Empty />}
      </ul>

      {taskData?.coordinationUsers?.includes(user.id) &&
      (taskData?.status.code === 'ACTIVE' || taskData?.status.code === 'NEW') &&
      coordination ? (
        <div
          className='task-coordination__wrap'
          style={{ marginTop: 'auto', marginBottom: '20px' }}
        >
          <Form form={formForCommentsCoordination} autoComplete='off'>
            <Form.Item
              name='commentCoordination'
              style={{ margin: 0, marginRight: '-1px' }}
            >
              <TextArea
                placeholder={t('task.actionHistory.module.commentPlaceholder')}
                style={{
                  width: '384px',
                  height: '32px',
                }}
              />
            </Form.Item>
          </Form>
          <Button
            style={{
              width: '384px',
              height: '32px',
              borderRadius: '26px',
              marginTop: '30px',
            }}
            loading={loading}
            type='primary'
            onClick={() => coordinationTask(true)}
          >
            {t('task.actionHistory.module.agreementSuccessBtn')}
          </Button>
          <Button
            style={{
              width: '384px',
              height: '32px',
              borderRadius: '26px',
              marginTop: '12px',
            }}
            loading={loading}
            type='danger'
            ghost
            onClick={() => coordinationTask(false)}
          >
            {t('request.common.button.reject')}
          </Button>
        </div>
      ) : null}
      <HistoryModal
        open={open}
        nameModal={nameModal}
        infoModal={infoModal}
        userModal={userModal}
        handleCancel={handleCancel}
        bodyModal={bodyModal}
      />
      <Modal
        visible={openModalComment}
        title={t('task.actionHistory.modal.warehouse.comment')}
        style={{ top: 100 }}
        bodyStyle={{ paddingBottom: 40 }}
        onCancel={handleCancelComment}
        footer={false}
        width={792}
      >
        {formatHTML(commentModal, 'modal')}
      </Modal>
      <SwitchUserFromTask
        loading={loading}
        switchData={switchData}
        taskData={taskData}
        openDrawer={openDrawer}
        cancelDrawer={cancelDrawer}
      />
    </div>
  )
}
export default ClientHistory
