import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { authorizationLayer } from '@store/actions/methods'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import SkeletonTable from '@ui/SkeletonTable'
import { columns } from '@views/Warehouse/methods/columns'
import { getFilters } from '@views/Warehouse/methods/filters'
import moment from 'moment'
import { Input } from 'antd'
import { openModals } from '@utils'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const T = (props) => {
  const { t } = useTranslation()
  const { stockType, bodyHeight } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [markId, setMarkId] = useState(null)
  const [carModelId, setCarModelId] = useState(null)
  const [filtersData, setFiltersData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [meta, setMeta] = useState(null)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
  })

  const [filters, setFilters] = useState(null)
  const [sorter, setSorter] = useState({
    field: 'carMark',
    order: 'ascend',
  })

  useEffect(async () => {
    const q = {
      page: pagination.current,
      take: pagination.pageSize,
    }
    const query = queryString.stringify(q)
    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    if (!filters?.carMark && filters) {
      filters.carModel = undefined
    }
  }, [markId])

  useEffect(() => {
    if (!filters?.carModel && filters) {
      filters.carTemplate = undefined
    }
  }, [carModelId])

  const handleTableChange = async (pagination, filters, sorter, extra) => {
    setSorter({
      field: sorter.field,
      order: sorter.order,
    })

    setFilters(filters)

    setPagination({
      current: 1,
      pageSize: 15,
    })
    let q = {
      page: 1,
      take: 15,
    }

    if (searchValue.length) q.keyword = searchValue

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.carMark) {
            setMarkId(filters?.carMark)
            q.car_mark_id = filters?.carMark
          } else {
            setMarkId(null)
            filters.carModel = null
          }
          if (filters?.carMark && filters?.carModel) {
            setCarModelId(filters?.carModel)
            q.car_model_id = filters?.carModel
          } else {
            setCarModelId(null)
          }

          if (filters?.carMark && filters?.carModel && filters?.carTemplate)
            q.car_template_id = filters?.carTemplate
          if (filters?.conditions) q.condition = filters?.conditions
          if (filters?.carExteriorColor)
            q.car_exterior_color_id = filters?.carExteriorColor
          if (filters?.carInteriorColor)
            q.car_interior_color_id = filters?.carInteriorColor
          if (filters?.mileage) q.mileage_to = filters?.mileage
          if (filters?.year) {
            q.year_from = filters?.year[0]
            q.year_to = filters?.year[1]
          }
          if (filters?.price) {
            q.price_from = filters?.price[0]
            q.price_to = filters?.price[1]
          }
          if (filters?.engine_capacity) {
            q.engine_capacity_from = filters?.engine_capacity[0]
            q.engine_capacity_to = filters?.engine_capacity[1]
          }
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })

    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }
  const handleSearch = async (value) => {
    if (value) setSearchValue(value.target.value.trim())

    setPagination({
      current: 1,
      pageSize: 15,
    })

    let q = {
      page: 1,
      take: 15,
    }

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (value && value.target.value.length)
      q.keyword = value.target.value.trim()

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.carMark) {
            setMarkId(filters?.carMark)
            q.car_mark_id = filters?.carMark
          } else {
            setMarkId(null)
            q.car_mark_id = undefined
          }
          if (filters?.carMark && filters?.carModel) {
            setCarModelId(filters?.carModel)
            q.car_model_id = filters?.carModel
          } else {
            setCarModelId(null)
          }
          if (filters?.carMark && filters?.carModel && filters?.carTemplate)
            q.car_template_id = filters?.carTemplate
          if (filters?.status) q.status = filters?.status
          if (filters?.conditions) q.condition = filters?.conditions
          if (filters?.carExteriorColor)
            q.car_exterior_color_id = filters?.carExteriorColor
          if (filters?.carInteriorColor)
            q.car_interior_color_id = filters?.carInteriorColor
          if (filters?.mileage) q.mileage_to = filters?.mileage
          if (filters?.year) {
            q.year_from = filters?.year[0]
            q.year_to = filters?.year[1]
          }
          if (filters?.price) {
            q.price_from = filters?.price[0]
            q.price_to = filters?.price[1]
          }
          if (filters?.engine_capacity) {
            q.engine_capacity_from = filters?.engine_capacity[0]
            q.engine_capacity_to = filters?.engine_capacity[1]
          }

          if (filters?.stock_date) {
            q.stock_date_from = moment(filters?.stock_date[0]?._d).format(
              'YYYY-MM-DD'
            )
            q.stock_date_to = moment(filters?.stock_date[1]?._d).format(
              'YYYY-MM-DD'
            )
          }
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData(res.data.data)
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(async () => {
    setLoading(true)

    const filters = await getFilters(
      dispatch,
      authorizationLayer,
      stockType,
      markId,
      carModelId
    )

    setFiltersData(filters)
  }, [getFilters, dispatch, markId, carModelId])

  const sortedColumns = columns(
    stockType,
    filtersData,
    markId,
    carModelId,
    filters
  ).filter((col) => Object.keys(col).length != 0)

  const infinityFunction = async () => {
    pagination.current = pagination.current + 1

    let q = {
      page: pagination.current || 1,
      take: pagination.pageSize || 15,
    }

    if (searchValue.length) q.keyword = searchValue

    if (Object.keys(sorter)) {
      q.sortElement = sorter.field
      q.sortBy = sorter.order === 'ascend' || !sorter.order ? 'ASC' : 'DESC'
    }

    if (filters) {
      Object.values(filters).forEach((dataIndex) => {
        if (dataIndex) {
          if (filters?.carMark) {
            setMarkId(filters?.carMark)
            q.car_mark_id = filters?.carMark
          } else {
            setMarkId(null)
            filters.carModel = null
          }
          if (filters?.carMark && filters?.carModel) {
            setCarModelId(filters?.carModel)
            q.car_model_id = filters?.carModel
          } else {
            setCarModelId(null)
          }

          if (filters?.carMark && filters?.carModel && filters?.carTemplate)
            q.car_template_id = filters?.carTemplate
          if (filters?.conditions) q.condition = filters?.conditions
          if (filters?.carExteriorColor)
            q.car_exterior_color_id = filters?.carExteriorColor
          if (filters?.carInteriorColor)
            q.car_interior_color_id = filters?.carInteriorColor
          if (filters?.mileage) q.mileage_to = filters?.mileage
          if (filters?.year) {
            q.year_from = filters?.year[0]
            q.year_to = filters?.year[1]
          }
          if (filters?.price) {
            q.price_from = filters?.price[0]
            q.price_to = filters?.price[1]
          }
          if (filters?.engine_capacity) {
            q.engine_capacity_from = filters?.engine_capacity[0]
            q.engine_capacity_to = filters?.engine_capacity[1]
          }
        }
      })
    }

    const query = queryString.stringify(q, { arrayFormat: 'comma' })

    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `wh-cars/car_task/car/${stockType}?${query}`,
      })
    )
      .then((res) => {
        setData([...data, ...res.data.data])
        setMeta({
          page: res.data.page,
          take: res.data.take,
          total: res.data.total,
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <Search
        placeholder={t('main.search')}
        allowClear
        style={{ width: 400, marginBottom: 15 }}
        onChange={_.debounce(handleSearch, 500)}
      />
      <SkeletonTable
        columns={sortedColumns}
        data={data}
        loading={loading}
        xScroll
        className='xScroll-custom-table--warehouse'
        total={meta?.total}
        currentPage={meta?.page}
        pageSize={meta?.take}
        tableChangeFunction={(_, filters, sorter) =>
          handleTableChange(_, filters, sorter)
        }
        pageSizeOptions={[10, 20, 50, 100]}
        width={1000}
        onRowClickTrue={(e) => {
          openModals(dispatch, {
            type: 'product-details',
            id: e,
            spareStatus: false,
          })
        }}
        onRowClickStatus={() => true}
        cursorPointer
        height={bodyHeight}
        infinityFunction={infinityFunction}
      />
    </div>
  )
}

export default T
