import React, { useEffect, useState, useContext } from 'react'
import { forwardRef, useRef, useImperativeHandle } from 'react'
import { Button, Drawer, Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import './index.less'
import { ModuleContext } from '../../..'
import { SSE } from '@utils'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const DepartmentsInteraction = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.app)
  const { module, taskData, currentNeeds, cardTitle, needsData, setNeedsData } =
    useContext(ModuleContext)
  const [loading, setLoading] = useState(false)
  const [task, setTask] = useState({})
  const [responsibleUser, setResponsibleUser] = useState('')
  const [nameButton, setNameButton] = useState(
    t('task.moduleRender.module.coordination.sendRequestBtn')
  )
  const [status, setStatus] = useState()
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [infoVisible, setInfoVisible] = useState(true)
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState([])
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    if (currentNeeds !== undefined) {
      setResponsibleUser(currentNeeds.meta.responsibleUser)
      if (currentNeeds.meta?.taskStatus === 'CANCELLED') {
        setInfoVisible(false)
        setStatus(
          <p className='dep-info__status  dep-info__status--error'>
            {t(
              'task.moduleRender.module.interactionWithDepartments.rejecetedStatus'
            )}
          </p>
        )
        setDisableSubmit(false)
        setNameButton(
          t('task.moduleRender.module.interactionWithDepartments.resendBtn')
        )
      } else if (currentNeeds.meta?.taskStatus === 'ACTIVE') {
        setInfoVisible(false)
        setStatus(
          <p className='dep-info__status'>
            {t(
              'task.moduleRender.module.interactionWithDepartments.requestSent'
            )}
          </p>
        )
        setDisableSubmit(true)
      } else if (currentNeeds.meta?.taskStatus === 'COMPLETED') {
        setInfoVisible(false)
        setStatus(
          <p className='dep-info__status  dep-info__status--closed'>
            {t(
              'task.moduleRender.module.interactionWithDepartments.completedStatus'
            )}
          </p>
        )
        setDisableSubmit(true)
      }
    }
  }, [currentNeeds])

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        if (module?.isRequired)
          return currentNeeds?.meta?.taskStatus === 'COMPLETED'
        else return true
      },
    }),
    [currentNeeds]
  )

  const CREATED_TASK_UPDATED = `CREATED_TASK_UPDATED_${taskData?.mainTaskId}_${module?.id}`

  const onUpdated = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event

    if (data?.meta.taskStatus === 'CANCELLED') {
      setInfoVisible(false)
      setStatus(
        <p className='dep-info__status  dep-info__status--error'>
          {t(
            'task.moduleRender.module.interactionWithDepartments.rejecetedStatus'
          )}
        </p>
      )
      setDisableSubmit(false)
      setNameButton(
        t('task.moduleRender.module.interactionWithDepartments.resendBtn')
      )
    } else if (data?.meta?.taskStatus === 'ACTIVE') {
      setInfoVisible(false)
      setStatus(
        <p className='dep-info__status'>
          {t('task.moduleRender.module.interactionWithDepartments.requestSent')}
        </p>
      )
      setDisableSubmit(true)
    } else if (data?.meta?.taskStatus === 'COMPLETED') {
      setInfoVisible(false)
      setStatus(
        <p className='dep-info__status  dep-info__status--closed'>
          {t(
            'task.moduleRender.module.interactionWithDepartments.completedStatus'
          )}
        </p>
      )
      setDisableSubmit(true)
      let index = _.findIndex(
        needsData,
        (i) => i.module?.id === data?.module?.id
      )

      if (index !== -1) {
        needsData[index] = data

        setNeedsData([...needsData])
      } else {
        needsData?.push(data)
        setNeedsData([...needsData])
      }
    }
  }

  const sse = useRef()

  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(CREATED_TASK_UPDATED, onUpdated)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(CREATED_TASK_UPDATED, onUpdated)
      }
    }
  }, [])

  const getUsers = async () => {
    await dispatch(
      authorizationLayer({
        url: `processes/process-user/${module?.settings?.processId}/users`,
      })
    ).then(({ data }) => {
      if (data.success) {
        let userData = data.data
        userData?.forEach((item) => {
          if (item.id === user?.id) {
            item.full_name = 'Создать на себя'
          }
        })

        if (
          data.data?.length === 1 &&
          data.data?.some((x) => x.id === user?.id)
        )
          setUsers(userData)
        else
          setUsers([
            {
              id: 'auto',
              label: t(
                'request.actionHistory.changeUser.drawer.selectAutomatically'
              ),
            },
            ...userData,
          ])
      }
    })
  }

  const onSubmit = async (values) => {
    setLoading(true)
    let responsibleUser = ''
    await dispatch(
      authorizationLayer({
        url: `router/create-task`,
        method: 'POST',
        data: {
          client_phone_number: taskData.client?.phone_number,
          processId: module?.settings?.processId,
          userId:
            values.user !== undefined && values.user !== 'auto'
              ? values.user
              : values.user === 'auto'
              ? null
              : values.user,
          parentTaskId: taskData.id,
          comment: values.comment !== undefined ? values.comment : null,
          moduleId: module?.id,
        },
      })
    ).then((res) => {
      let NewTaskId = res.data?.taskId
      responsibleUser = res.data.lastName + ' ' + res.data.name
      setOpen(false)

      dispatch(
        authorizationLayer({
          url: `processes/tasks/${taskData.id}/${module.id}/needs`,
          method: 'POST',
          data: [
            {
              type: 'interactionWithDepartments',
              action: 'choice',
              meta: {
                taskId: NewTaskId,
                taskStatus: 'ACTIVE',
                responsibleUser,
              },
            },
          ],
        })
      )
        .then(() => {
          setStatus(
            <p className='dep-info__status'>
              {t(
                'task.moduleRender.module.interactionWithDepartments.requestSent'
              )}
            </p>
          )
          setDisableSubmit(true)
          setResponsibleUser(responsibleUser)
          setInfoVisible(false)
        })
        .finally(() => setLoading(false))
    })
  }

  return (
    <div>
      {cardTitle}
      <div
        className='dep-info'
        style={{ display: infoVisible ? 'none' : 'block' }}
      >
        {status}
        <div className='dep-info__user-wrap'>
          <div className='dep-info__user'>
            <span className='dep-info__user-responsible'>
              {t('task.moduleRender.module.interactionWithDepartments.who')}:{' '}
            </span>
            {responsibleUser}
          </div>
          {/* TODO Доработать */}
          {/*<a href={currentNeeds !== undefined ? currentNeeds.meta.taskId : null} className="dep-info__history">История заявки</a>*/}
        </div>
      </div>
      <Button
        ghost
        shape='round'
        type='primary'
        disabled={disableSubmit}
        onClick={() => showDrawer()}
        loading={loading}
      >
        {nameButton}
      </Button>
      <Drawer
        title={t(
          'task.moduleRender.module.interactionWithDepartments.anotherDep'
        )}
        placement='right'
        onClose={onClose}
        open={open}
        width={'448px'}
      >
        <Form
          form={form}
          onFinish={onSubmit}
          onFinishFailed={(values) => console.log(values)}
          layout='vertical'
          autoComplete='off'
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          initialValues={{
            appeal_type: 'crm',
          }}
        >
          <Form.Item name='user' label={t('main.createTask.changeUserLabel')}>
            <Select
              placeholder={t('main.createTask.changeUserPlaceholder')}
              size='large'
              className='task-create__select'
              disabled={!module?.settings?.processId || loading}
              defaultValue={
                users?.length > 2
                  ? 'auto'
                  : t(
                      'request.actionHistory.changeUser.drawer.selectCreateForMe'
                    )
              }
            >
              {users?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name || item.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name='comment'
            label={t('main.createTask.enterCommentLabel')}
          >
            <Input.TextArea
              placeholder={t('main.createTask.enterCommentPlaceholder')}
              style={{ borderRadius: 6 }}
              disabled={loading}
            />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            shape='round'
            style={{ width: '100%', marginTop: 'auto' }}
            size='large'
            loading={loading}
          >
            {t('task.actionHistory.switchUserFromTask.sendBtn')}
          </Button>
        </Form>
      </Drawer>
    </div>
  )
})

export default DepartmentsInteraction
