import React, { useContext } from 'react'
import { Form, Input } from 'antd'
import { CharacteristicsContext } from '../..'
import { useTranslation } from 'react-i18next'

const VIN = () => {
  const { updateInfo, brandId, modelId } = useContext(CharacteristicsContext)
  const form = Form.useFormInstance()
  const { t } = useTranslation()

  return (
    <Form.Item
      id='vin'
      label={t('warehouse.tab.cars.column.vin')}
      name='vin'
      rules={[
        {
          required: true,
          message: t('common.input.requiredMessage'),
        },
        {
          validator: (rule, value) => {
            if (/^[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\^&*|']*$/.test(value)) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(t('task.moduleRender.module.vinValidateMessage'))
            )
          },
        },
      ]}
    >
      <Input
        style={{
          width: '400px',
          marginRight: '20px',
        }}
        allowClear
        onChange={async (e) => {
          if (!e.target.value) updateInfo(brandId, modelId)
          try {
            const valid = await form.validateFields(['vin'])
            if (valid) updateInfo(brandId, modelId)
          } catch (e) {
            console.log(e)
          }
        }}
        onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
      />
    </Form.Item>
  )
}

export default VIN
