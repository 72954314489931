import React, { useEffect, useState, useContext } from 'react'
import { useRef, forwardRef, useImperativeHandle } from 'react'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { authorizationLayer } from '@store/actions/methods'
import {
  InfoCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import './index.less'
import { ModuleContext } from '../../..'
import { SSE } from '@utils'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const Coordination = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { module, taskData, currentNeeds, cardTitle, needsData, setNeedsData } =
    useContext(ModuleContext)
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState(
    t('task.moduleRender.module.coordination.defaultStatusMessage')
  )
  const [status, setStatus] = useState(null)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const CREATED_TASK_UPDATED = `CREATED_TASK_UPDATED_${taskData?.mainTaskId}_${module?.id}`

  const onUpdated = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event
    if (data?.meta?.taskStatus === 'ACTIVE') {
      setInfo(t('task.moduleRender.module.coordination.pendingStatusMessage'))
      setDisableSubmit(true)
      setStatus(null)
    }
    if (data?.meta?.taskStatus === 'COMPLETED') {
      setInfo(t('request.common.status.completed'))
      setDisableSubmit(true)
      setStatus(true)

      let index = _.findIndex(
        needsData,
        (i) => i.module?.id === data?.module?.id
      )

      if (index !== -1) {
        needsData[index] = data

        setNeedsData([...needsData])
      } else {
        needsData?.push(data)
        setNeedsData([...needsData])
      }
    }
    if (data?.meta?.taskStatus === 'CANCELLED') {
      setInfo(t('task.moduleRender.module.coordination.cancelledStatusMessage'))
      setDisableSubmit(false)
      setStatus(false)
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        if (module?.isRequired)
          return currentNeeds?.meta?.taskStatus === 'COMPLETED'
        else return true
      },
    }),
    [currentNeeds]
  )

  const sse = useRef()

  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(CREATED_TASK_UPDATED, onUpdated)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(CREATED_TASK_UPDATED, onUpdated)
      }
    }
  }, [])

  useEffect(() => {
    if (currentNeeds !== undefined) {
      if (currentNeeds.meta?.taskStatus === 'ACTIVE') {
        setInfo(t('task.moduleRender.module.coordination.pendingStatusMessage'))
        setDisableSubmit(true)
        setStatus(null)
      }
      if (currentNeeds.meta?.taskStatus === 'COMPLETED') {
        setInfo(t('request.common.status.completed'))
        setDisableSubmit(true)
        setStatus(true)
      }
      if (currentNeeds.meta?.taskStatus === 'CANCELLED') {
        setInfo(
          t('task.moduleRender.module.coordination.cancelledStatusMessage')
        )
        setDisableSubmit(false)
        setStatus(false)
      }
    }
  }, [currentNeeds])

  const onSubmit = async () => {
    setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `router/create-coordination-task`,
        method: 'POST',
        data: {
          taskId: taskData?.id,
          moduleId: module?.id,
        },
      })
    ).then(async (res) => {
      if (res.data) {
        await dispatch(
          authorizationLayer({
            url: `processes/tasks/${taskData?.id}/${module?.id}/needs`,
            method: 'POST',
            data: [
              {
                type: 'Coordination',
                action: 'choice',
                meta: {
                  taskId: res.data?.taskId,
                  taskStatus: 'ACTIVE',
                },
              },
            ],
          })
        )
          .then(() => {
            setInfo(
              t('task.moduleRender.module.coordination.pendingStatusMessage')
            )
            setDisableSubmit(true)
          })
          .finally(() => setLoading(false))
      }
    })
  }

  return (
    <div>
      {cardTitle}
      <Button
        ghost
        shape='round'
        type='primary'
        disabled={disableSubmit}
        onClick={() => onSubmit()}
        loading={loading}
      >
        {t('task.moduleRender.module.coordination.sendRequestBtn')}
      </Button>
      <div className='coordination'>
        {status === null ? (
          <InfoCircleOutlined style={{ fontSize: 16 }} />
        ) : status ? (
          <CheckCircleOutlined style={{ fontSize: 16, color: '#3BD378' }} />
        ) : !status ? (
          <CloseCircleOutlined style={{ fontSize: 16, color: '#FF4D4F' }} />
        ) : null}
        <span className='coordination__info'>{info}</span>
      </div>
    </div>
  )
})

export default Coordination
