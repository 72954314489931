import React, { useState, useEffect, createContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Modules, ClientCard } from './components'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import Loading from '@ui/Loading'
import _ from 'lodash'
import useBrands from './hooks/useBrands'
import useCarsColors from './hooks/useCarsColors'
import useDefectsByCategories from './hooks/useDefectsByCategories'
import { SSE } from '@utils'

export const TaskContext = createContext()

const Task = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const tasks = useSelector((state) => state.app.tasks)
  const [loading, setLoading] = useState(false)
  const [taskData, setTaskData] = useState()
  const [needsData, setNeedsData] = useState([])
  const [cancelReasons, setCancelReasons] = useState([])
  const [currentStageData, setCurrentStageData] = useState({})
  const { onChangeBrands } = useBrands()
  const { getExterior, getInterior } = useCarsColors()
  const { getCategories, getCarDetails, getDefects } = useDefectsByCategories()

  const TASK_UPDATED = `TASK_UPDATED_${id}`
  const sse = useRef()

  const onUpdatedTaskData = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event
    setTaskData(data)

    let index = _.findIndex(tasks, (i) => i.id === data?.id)

    if (index !== -1) {
      tasks[index] = data

      dispatch(SET_APP(['tasks'], [...tasks]))
    }
  }

  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(TASK_UPDATED, onUpdatedTaskData)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(TASK_UPDATED, onUpdatedTaskData)
      }
    }
  }, [])

  const getTaskData = async () => {
    setLoading(true)

    await dispatch(
      authorizationLayer({
        url: `processes/tasks/${id}`,
      })
    )
      .then(async ({ data }) => {
        await dispatch(
          authorizationLayer({
            url: `processes/tasks/${id}/needs`,
          })
        ).then(({ data }) => {
          setNeedsData(data.data.needs)
        })

        setTaskData(data.data)
        setCurrentStageData(data.data?.currentStage)
        setCancelReasons(data.data?.currentStage?.cancelReasons)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getTaskData()

    return () => {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (
      taskData?.currentStage?.modules?.filter(
        (i) => i.component.componentType.name === 'carReception'
      ).length
    ) {
      onChangeBrands()
      getExterior()
      getInterior()
      getDefects()
      getCategories().then((res) => {
        getCarDetails(
          'external',
          res.find((i) => i.short_name === 'external').id
        )
        getCarDetails(
          'internal',
          res.find((i) => i.short_name === 'internal').id
        )
      })
    }

    if (taskData?.status?.code === 'CANCELLED') navigate('/')
  }, [taskData])

  return (
    <div style={{ display: 'flex', padding: '0 0 0 24px' }}>
      {loading && <Loading spinner={true} />}
      <TaskContext.Provider
        value={{
          id,
          loading,
          taskData,
          needsData,
          cancelReasons,
          currentStageData,
          setCurrentStageData,
          setTaskData,
          setNeedsData,
        }}
      >
        <Modules />
        <ClientCard
          loading={loading}
          taskData={taskData}
          id={id}
          setTaskData={setTaskData}
        />
      </TaskContext.Provider>
    </div>
  )
}

export default Task
