export const getFilters = async (
  dispatch,
  authorizationLayer,
  stockType,
  carMarkId,
  carModelId
) => {
  let filtersData = {
    markData:
      stockType === 'new' || stockType === 'used'
        ? await dispatch(
            authorizationLayer({
              url: `wh-cars/references/car_marks/f9ca62d0-4eba-402f-8d6c-010102ddc59a`,
            })
          )
        : null,
    statusesData:
      stockType === 'new'
        ? await dispatch(
            authorizationLayer({
              url: `wh-cars/references/car_status`,
            })
          )
        : null,
    modelData:
      (carMarkId && stockType === 'new') || (carMarkId && stockType === 'used')
        ? await dispatch(
            authorizationLayer({
              url: `wh-cars/references/car_models?mark_id=${carMarkId}`,
            })
          )
        : null,

    templatesData:
      carModelId && stockType === 'new'
        ? await dispatch(
            authorizationLayer({
              url: `wh-cars/references/car_templates?model_id=${carModelId}`,
            })
          )
        : null,

    exteriorColors:
      stockType === 'new' || stockType === 'used'
        ? await dispatch(
            authorizationLayer({
              url: `wh-cars/references/car_exterior_colors`,
            })
          )
        : null,
    interiorColors:
      stockType === 'new' || stockType === 'used'
        ? await dispatch(
            authorizationLayer({
              url: `wh-cars/references/car_interior_colors`,
            })
          )
        : null,
    conditions:
      stockType === 'new' || stockType === 'used'
        ? [{ text: 'Нет условий', value: true }]
        : null,
    sparePartsCategory:
      stockType === 'spare_parts'
        ? await dispatch(
            authorizationLayer({
              url: `wh-parts/spare_parts_category`,
            })
          )
        : null,

    servicesCategory:
      stockType === 'services'
        ? await dispatch(
            authorizationLayer({
              url: 'wh-services/categories',
            })
          )
        : null,
  }

  return filtersData
}
