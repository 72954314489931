import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import moment from 'moment'
import { Dropdown, Row, Col, Menu, Space } from 'antd'
import logoComment from '@img/logo-comment.svg'
import { MoreOutlined } from '@ant-design/icons'
import Player from '../AudioPlayer'

const Comment = (props) => {
  const dispatch = useDispatch()
  const comments = useSelector((state) => state.app.comments)
  const [collapsed, setCollapsed] = useState(false)
  const comment = useRef(null)
  const commentWrap = useRef(null)

  const avtarName = (item) => {
    let name = item.split(' ')
    return (name[0][0] + '' + name[1][0]).toUpperCase()
  }

  const actionDropdownMenu = (id) => {
    return (
      <Menu
        items={[
          {
            label: (
              <span
                onClick={() => deleteComment(id)}
                style={{ width: '100%', height: '100%' }}
              >
                Удалить
              </span>
            ),
            key: 1,
          },
        ]}
      />
    )
  }

  const formatHTML = (item, type) => {
    if (item && Object.keys(item)?.length !== 0) {
      let result
      let newStr = item.event

      result = (
        <span
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
          dangerouslySetInnerHTML={{ __html: newStr }}
        />
      )

      return result
    }
  }

  const deleteComment = async (id) => {
    props.setLoading(true)
    await dispatch(
      authorizationLayer({
        url: `action_history/event/${id}`,
        method: 'DELETE',
      })
    )
      .then(() => {
        const newcomment = comments.filter((i) => i.event_id !== id)
        dispatch(SET_APP(['comments'], newcomment))
      })
      .finally(() => {
        props.setLoading(false)
      })
  }

  const renderFooterChange = (item) => {
    if (
      item?.event_name === 'task' ||
      item?.event_name === 'module_interaction'
    ) {
      if (
        (item?.event_group === 'default' ||
          item?.event_group === 'change_user') &&
        item?.event_type === 'create' &&
        item?.event_task_id === props.taskData?.id &&
        props.taskData?.userId === props.user?.id &&
        props.taskData?.status?.code !== 'COMPLETED' &&
        props.taskData?.status?.code !== 'CANCELLED'
      ) {
        return footerChange(item)
      }
    }
    return null
  }

  const footerChange = (item) => {
    return (
      <div
        className='history-comment__info-interaction'
        onClick={() => showDrawer(item)}
      >
        Нажмите, чтобы изменить
      </div>
    )
  }

  const showDrawer = async (item) => {
    await dispatch(
      authorizationLayer({
        url: `action_history/event/${item?.event_id}`,
        method: 'get',
      })
    ).then((res) => {
      if (res.data) {
        props.setSwitchData(res.data?.event_value)
        props.setOpenDrawer(true)
      }
    })
  }

  const showComment = () => {
    setCollapsed(!collapsed)
    comment.current.style.display = 'block'
    comment.current.style.webkitLineClamp = 'none'
    comment.current.style.maxHeight = '100%'
    commentWrap.current.scrollIntoView({ behavior: 'smooth' })
  }

  const hideComment = () => {
    setCollapsed(!collapsed)
    comment.current.style.display = '-webkit-box'
    comment.current.style.webkitLineClamp = '14'
    comment.current.style.maxHeight = 'calc(14 * 1.5 * 12px)'
    commentWrap.current.scrollIntoView({ behavior: 'smooth' })
  }

  const showModal = async (eventId, eventName, eventGroup, user) => {
    if (eventName === 'module_upload') {
      props.setNameModal('Файлы')
    } else if (
      eventName === 'module_cars' ||
      eventName === 'module_test_drive' ||
      eventName === 'module_parts'
    ) {
      props.setNameModal('Потребность')
    } else if (eventName === 'module_form') {
      props.setNameModal('Форма')
    } else if (eventName === 'warehouse_cars') {
      props.setNameModal('Характеристики авто')
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'checklist'
    ) {
      props.setNameModal('Опции')
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'quality_control'
    ) {
      props.setNameModal('Тест-драйв')
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'external'
    ) {
      props.setNameModal('Круговой осмотр')
    } else if (
      eventName === 'module_car_reception' &&
      eventGroup === 'internal'
    ) {
      props.setNameModal('Внутренний осмотр')
    }
    props.setInfoModal(props.taskData?.process.name)
    props.setUserModal(user.full_name)

    await dispatch(
      authorizationLayer({
        url: `action_history/event/${eventId}`,
        method: 'get',
      })
    ).then((res) => {
      if (res.data) {
        props.setBodyModal(res.data)
        props.setOpen(true)
      }
    })
  }

  return (
    <div className='history-comment__wrap' ref={commentWrap}>
      <div className='history-comment__user'>
        {props.item?.user ? (
          <div
            className={
              props.user?.id === props.item?.user?.id
                ? 'history-comment__avatar'
                : 'history-comment__avatar history-comment__avatar--light'
            }
          >
            {avtarName(props.item?.user.full_name)}
          </div>
        ) : (
          <div className='history-comment__avatar'>
            <img src={logoComment} alt='bertha' />
          </div>
        )}
        <div className='history-comment__user-wrap'>
          {props.item?.user ? (
            <span className='history-comment__user-name'>
              {props.item?.user.full_name}
            </span>
          ) : (
            <span className='history-comment__user-name'>Bertha</span>
          )}
          <span className='history-comment__date'>
            {moment(props.item?.date).format('DD MMM YYYY HH:mm')}
          </span>
        </div>
        {props.user?.id === props.item?.user?.id &&
        props.item?.event_group === 'comment' ? (
          <div className='history-comment__actions'>
            <Dropdown
              placement='bottomLeft'
              overlay={() => actionDropdownMenu(props.item?.event_id)}
              trigger={['click']}
            >
              <MoreOutlined style={{ cursor: 'pointer', fontSize: 20 }} />
            </Dropdown>
          </div>
        ) : null}
      </div>
      <div className='history-comment__info'>
        <span
          id={`info-text_${props.item?.event_id}`}
          className='history-comment__info-text'
          ref={comment}
        >
          {formatHTML(props.item, 'in')}
          {props.item?.event_name === 'module_form' && (
            <>
              <br />
              <br />
              <Space direction='vertical' size={6}>
                {props.item?.event_value.fields?.map((i) => {
                  return (
                    <Row key={i.id} justify='space-between' gutter={[16, 16]}>
                      <Col span={12}>{i.name}</Col>
                      <Col
                        span={12}
                        style={{
                          wordBreak: 'break-word',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                        }}
                      >
                        {i.fieldData
                          ? i.fieldData
                          : i.fieldDataList?.join(', ')}
                      </Col>
                    </Row>
                  )
                })}
              </Space>
            </>
          )}
        </span>
        {props.item?.full_record_file_link ? (
          <div className='task__control'>
            <Player url={props.item?.full_record_file_link} />
          </div>
        ) : null}
        {renderFooterChange(props.item)}
        {(props.item?.event_group === 'comment' &&
          props.item?.event_group !== 'client' &&
          comment.current?.clientHeight > 250) ||
        (props.item?.event_name === 'module_form' &&
          props.item?.event_group !== 'client' &&
          comment.current?.clientHeight > 250) ? (
          <div
            className='history-comment__info-interaction'
            onClick={() => (!collapsed ? showComment() : hideComment())}
          >
            {!collapsed ? 'Посмотреть полностью' : 'Скрыть'}
          </div>
        ) : null}
        {props.item?.event_group !== 'comment' &&
        props.item?.event_name !== 'module_interaction' &&
        props.item?.event_name !== 'task' &&
        props.item?.event_name !== 'client' &&
        props.item?.event_name !== 'module_form' &&
        props.item?.event_name !== 'stage' ? (
          <div
            className='history-comment__info-interaction'
            onClick={() =>
              showModal(
                props.item?.event_id,
                props.item?.event_name,
                props.item?.event_group,
                props.item?.user
              )
            }
          >
            Посмотреть полностью
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Comment
