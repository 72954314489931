import { List, Typography, Popover } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import { useNavigate } from 'react-router'
import './index.less'
import { t } from 'i18next'

const { Text } = Typography

const getPopoverContent = (
  autoDataNew,
  autoDataUsed,
  autoDataTestDrive,
  partsData,
  servicesData
) => {
  return (
    <div
      style={{
        maxHeight: '100px',
        overflowY: 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
      className='custom-popover-inner'
    >
      {autoDataNew?.length ? (
        <Text type='secondary'>{t('clientCard.history.newAuto')}</Text>
      ) : null}
      {autoDataNew?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataUsed?.length ? (
        <Text type='secondary'>{t('clientCard.history.usedAuto')}</Text>
      ) : null}
      {autoDataUsed?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataTestDrive?.length ? (
        <Text type='secondary'>{t('clientCard.history.testDrive')}</Text>
      ) : null}
      {autoDataTestDrive?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {partsData?.length ? (
        <Text type='secondary'>{t('clientCard.history.parts')}</Text>
      ) : null}
      {partsData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {servicesData?.length ? (
        <Text type='secondary'>{t('clientCard.history.service')}</Text>
      ) : null}
      {servicesData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const ActiveTask = ({
  activeTaskList,
  setPagination,
  hasMore,
  setHistory,
  setVisibleHistory,
  user,
}) => {
  const navigate = useNavigate()

  const needsItem = (record) => {
    let autoDataNew = record.clientData ? record.clientData.cars.new : []
    let autoDataUsed = record.clientData ? record.clientData.cars.used : []
    let autoDataTestDrive = record.clientData
      ? record.clientData.cars.test_drive
      : []

    let partsData = record.clientData ? record.clientData?.parts : []
    let servicesData = record.clientData ? record.clientData?.services : []
    return (
      <span
        style={{
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {!autoDataNew?.length &&
        !autoDataUsed?.length &&
        !autoDataTestDrive?.length &&
        !partsData?.length &&
        !servicesData?.length ? (
          <Text>Потребность не выбрана</Text>
        ) : (
          <Popover
            overlayClassName='custom-popover'
            trigger='hover'
            placement='bottomLeft'
            title={
              <div onClick={(e) => e.stopPropagation()}>{`${t(
                'clientCard.history.selected'
              )} ${
                autoDataNew?.length +
                partsData?.length +
                servicesData?.length +
                autoDataUsed?.length +
                autoDataTestDrive?.length
              }`}</div>
            }
            content={getPopoverContent(
              autoDataNew,
              autoDataUsed,
              autoDataTestDrive,
              partsData,
              servicesData
            )}
          >
            <Text
              style={{
                cursor: 'pointer',
              }}
              // type='secondary'
            >{`${t('clientCard.history.selectedNeed')} ${
              autoDataNew?.length +
              partsData?.length +
              servicesData?.length +
              autoDataUsed?.length +
              autoDataTestDrive?.length
            }`}</Text>
          </Popover>
        )}
      </span>
    )
  }

  const height = () => {
    const windowHeight = document.body.offsetHeight
    const headerHeight = 55
    return windowHeight - headerHeight
  }

  return (
    <InfiniteScroll
      dataLength={activeTaskList?.length}
      next={() => {
        setPagination((value) => ({
          ...value,
          current: value.current + 1,
        }))
      }}
      height={height()}
      hasMore={hasMore}
      className='new_scroll'
    >
      <List
        dataSource={activeTaskList}
        renderItem={(item) => (
          <>
            <List.Item
              key={item.id}
              className='history__item'
              onClick={(e) => {
                e.stopPropagation()
                if (user?.full_name === item?.user?.full_name) {
                  navigate(`task/${item.id}`)
                } else {
                  setHistory(item)
                  setVisibleHistory(true)
                }
              }}
              style={{
                flexDirection: 'column',
                alignItems: 'normal',
                padding: '12px 20px 5px 20px',
                borderBottom: '1px solid #fafafa',
                cursor: 'pointer',
              }}
            >
              <List.Item.Meta />
              <div className='history__box'>
                <p className='history__title history__p'>{needsItem(item)}</p>
                <p className='history__id history__p'>{item?.inner_id}</p>
              </div>

              <div className='history__box history__box--date'>
                <p className='history__subtitle history__p'>
                  {item?.currentStage?.name}
                </p>
                <p className='history__date history__p'>
                  {moment(item?.created_at).format('DD MMMM YYYY, HH:mm')}
                </p>
              </div>
              <p
                className='history__p'
                style={{ fontSize: '14px', fontWeight: '500' }}
              >
                {item?.user?.full_name}
              </p>
            </List.Item>
          </>
        )}
      />
    </InfiniteScroll>
  )
}

export default ActiveTask
