import { useDispatch, useSelector } from 'react-redux'
import { authorizationLayer, SET_APP } from '@store/actions/methods'
import { useEffect } from 'react'
import _ from 'lodash'

const useFilters = (markId, carModelId, stockType) => {
  const dispatch = useDispatch()
  const { filtersData } = useSelector((state) => state.app)

  useEffect(() => {
    if (_.isEmpty(filtersData)) dispatch(SET_APP(['filtersData'], {}))
  }, [])

  const getFilters = async () => {
    let filtersData = {}
    if (stockType !== 'spare_parts') {
      await dispatch(
        authorizationLayer({
          url: `wh-cars/references/car_marks/f9ca62d0-4eba-402f-8d6c-010102ddc59a`,
        })
      ).then((res) => {
        filtersData.markData = res.data
      })

      await dispatch(
        authorizationLayer({
          url: `wh-cars/references/car_exterior_colors`,
        })
      ).then((res) => {
        filtersData.exteriorColors = res.data
      })

      await dispatch(
        authorizationLayer({
          url: `wh-cars/references/car_interior_colors`,
        })
      ).then((res) => {
        filtersData.interiorColors = res.data
      })
    } else {
      await dispatch(
        authorizationLayer({
          url: `wh-parts/spare_parts_category`,
        })
      ).then((res) => {
        filtersData.sparePartsCategory = res.data
      })
    }

    if (stockType === 'new') {
      await dispatch(
        authorizationLayer({
          url: `wh-cars/references/car_status`,
        })
      ).then((res) => {
        filtersData.statusesData = res.data
      })
    }

    if (stockType === 'new' || stockType === 'used') {
      filtersData.conditions = [{ text: 'Нет условий', value: true }]
    }

    dispatch(SET_APP(['filtersData'], filtersData))
  }

  const getModelFilters = async () => {
    if (markId && stockType !== 'spare_parts') {
      await dispatch(
        authorizationLayer({
          url: `wh-cars/references/car_models?mark_id=${markId}`,
        })
      ).then((res) => {
        filtersData.modelData = res.data
      })
    }

    dispatch(SET_APP(['filtersData'], filtersData))
  }

  const getTemplateFilters = async () => {
    if (carModelId && stockType === 'new') {
      await dispatch(
        authorizationLayer({
          url: `wh-cars/references/car_templates?model_id=${carModelId}`,
        })
      ).then((res) => {
        filtersData.templatesData = res.data
      })
    }

    dispatch(SET_APP(['filtersData'], filtersData))
  }

  return {
    getFilters,
    getModelFilters,
    getTemplateFilters,
  }
}

export default useFilters
