import React, { useState, useEffect } from 'react'
import {
  Calendar,
  Col,
  Row,
  Select,
  Tooltip,
  Button,
  Popover,
  Typography,
} from 'antd'

import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { authorizationLayer } from '@store/actions/methods'
import { MessageOutlined } from '@ant-design/icons'
import { phoneFormat } from '@utils'
import useHeight from '@hooks/useHeight'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import './index.less'
import { t } from 'i18next'
const { Text } = Typography

const getPopoverContent = (
  autoDataNew,
  autoDataUsed,
  autoDataTestDrive,
  partsData,
  servicesData
) => {
  return (
    <div
      style={{
        maxHeight: '100px',
        overflowY: 'auto',
      }}
      onClick={(e) => e.stopPropagation()}
      className='custom-popover-inner'
    >
      {autoDataNew?.length ? (
        <Text type='secondary'>{t('clientCard.history.newAuto')}</Text>
      ) : null}
      {autoDataNew?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataUsed?.length ? (
        <Text type='secondary'>{t('clientCard.history.usedAuto')}</Text>
      ) : null}
      {autoDataUsed?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {autoDataTestDrive?.length ? (
        <Text type='secondary'>{t('clientCard.history.testDrive')}</Text>
      ) : null}
      {autoDataTestDrive?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {partsData?.length ? (
        <Text type='secondary'>{t('clientCard.history.parts')}</Text>
      ) : null}
      {partsData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
      {servicesData?.length ? (
        <Text type='secondary'>{t('clientCard.history.service')}</Text>
      ) : null}
      {servicesData?.map((item, index) => {
        return (
          <div
            style={{
              minWidth: 204,
            }}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const CalendarBlock = ({ setActiveTab, setVisibleDrawer, setClientInfo }) => {
  const [selectedValue, setSelectedValue] = useState(() => moment())
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [filteredData, setFilteredData] = useState([])
  const { bodyHeight } = useHeight(['ant-layout-header'])
  const { t } = useTranslation()

  const onSelect = (newValue) => {
    setSelectedValue(newValue)
  }

  let select = selectedValue.format('YYYY-MM-DD')

  let firstDay = moment(select, 'YYYY-MM-DD')
    .startOf('month')
    .format('YYYY-MM-DD')
  let lastDay = moment(select, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')

  useEffect(() => {
    const getAllTask = async () => {
      try {
        setLoading(true)

        const q = {}

        if (firstDay && lastDay) {
          q.from = firstDay
          q.to = lastDay
        }

        const query = queryString.stringify(q)

        const { data: response } = await dispatch(
          authorizationLayer({ url: `processes/tasks/history?${query}` })
        )

        const data = response?.data

        setData(data)
        setFilteredData(data)

        if (!data) return
        setLoading(false)
      } catch (e) {
        console.log(e.message)
      }
    }
    getAllTask()
  }, [dispatch, firstDay, lastDay])

  const dateCellRender = (value) => {
    const cellData = value.format('YYYY-MM-DD')

    let isPausedTaskExist = data?.find((task) => {
      return task.pausedTo?.substring(0, 10) === cellData
    })

    if (isPausedTaskExist !== undefined) {
      return <div className='calendar__dot'></div>
    }
  }

  useEffect(() => {
    setFilteredData(
      data?.filter(
        (item) => moment(item.pausedTo).format('YYYY-MM-DD') === select
      )
    )
  }, [select, data])

  const nameInfo = (record) => {
    return (
      <div>
        {record.client?.phone_number ? (
          <Typography.Text>
            {record.client?.full_name ||
            record.client?.name ||
            record.client.phone_number
              ? record.client?.full_name ||
                record.client?.name ||
                `+${phoneFormat(record.client?.phone_number)}`
              : t('main.table.column.client.unnamed')}
          </Typography.Text>
        ) : (
          <Typography.Text>
            {t('main.table.column.client.unnamed')}
          </Typography.Text>
        )}
      </div>
    )
  }

  const needsItem = (record) => {
    let autoDataNew = record.clientData ? record.clientData.cars.new : []
    let autoDataUsed = record.clientData ? record.clientData.cars.used : []
    let autoDataTestDrive = record.clientData
      ? record.clientData.cars.test_drive
      : []

    let partsData = record.clientData ? record.clientData?.parts : []
    let servicesData = record.clientData ? record.clientData?.services : []
    return (
      <span
        style={{
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {!autoDataNew?.length &&
        !autoDataUsed?.length &&
        !autoDataTestDrive?.length &&
        !partsData?.length &&
        !servicesData?.length ? (
          <Text type='secondary'>
            {t('main.tab.pendingRequests.needIsNotSelected')}
          </Text>
        ) : (
          <Popover
            overlayClassName='custom-popover'
            trigger='hover'
            placement='bottomLeft'
            title={
              <div onClick={(e) => e.stopPropagation()}>{`${t(
                'clientCard.history.selected'
              )} ${
                autoDataNew?.length +
                partsData?.length +
                servicesData?.length +
                autoDataUsed?.length +
                autoDataTestDrive?.length
              }`}</div>
            }
            content={getPopoverContent(
              autoDataNew,
              autoDataUsed,
              autoDataTestDrive,
              partsData,
              servicesData
            )}
          >
            <Text
              style={{
                cursor: 'pointer',
              }}
              // type='secondary'
            >{`${t('clientCard.history.selectedNeed')} ${
              autoDataNew?.length +
              partsData?.length +
              servicesData?.length +
              autoDataUsed?.length +
              autoDataTestDrive?.length
            }`}</Text>
          </Popover>
        )}
      </span>
    )
  }

  return (
    <>
      <Calendar
        style={{ padding: '0px 5px 0' }}
        fullscreen={false}
        onSelect={onSelect}
        dateCellRender={dateCellRender}
        className='calendar'
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0
          const end = 12
          const monthOptions = []
          let current = value.clone()
          const localeData = value.localeData()
          const months = []

          for (let i = 0; i < 12; i++) {
            current = current.month(i)
            months.push(localeData.monthsShort(current))
          }

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className='month-item'>
                {months[i].charAt(0).toUpperCase() + months[i].slice(1)}
              </Select.Option>
            )
          }
          const year = value.year()
          const month = value.month()

          const options = []
          for (let i = year - 1; i < year + 5; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className='year-item'>
                {i}
              </Select.Option>
            )
          }

          return (
            <div
              style={{
                padding: '30px 30px 20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <button
                className='pausedBox__btn'
                onClick={() => setActiveTab('tab1')}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  color: '#0989FF',
                }}
              >
                <div
                  style={{
                    border: 'solid #0989FF',
                    borderWidth: '0 1px 1px 0',
                    display: 'inline-block',
                    padding: '3px',
                    transform: 'rotate(135deg)',
                    marginRight: '6px',
                  }}
                ></div>
                {t('main.tab.pendingRequests.back')}
              </button>
              <div>
                <Row gutter={8} justify='end'>
                  <Col>
                    <Select
                      size='small'
                      dropdownMatchSelectWidth={false}
                      className='calendar__border'
                      value={year}
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear)
                        onChange(now)
                      }}
                    >
                      {options}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      size='small'
                      dropdownMatchSelectWidth={false}
                      className='calendar__border'
                      value={month}
                      onChange={(newMonth) => {
                        const now = value.clone().month(newMonth)
                        onChange(now)
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                </Row>
              </div>
            </div>
          )
        }}
      />

      <ul
        className='pausedBox__list '
        style={{
          overflow: 'scroll ',
          height: bodyHeight - 353,
          paddingTop: '10px',
          paddingBottom: '0px',
        }}
      >
        {filteredData?.length > 0 ? (
          filteredData?.map((item) => {
            return (
              <div key={item.id}>
                <li
                  className='pausedBox__item'
                  style={{ zIndex: '-1' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`task/${item.id}`)
                  }}
                >
                  <div className='pausedBox__name-box'>
                    <p className='pausedBox__name'>{nameInfo(item)}</p>
                    <p className='pausedBox__id'>{item.inner_id}</p>
                  </div>
                  <div className='pausedBox__needs-box'>
                    <p className='pausedBox__needs'>{needsItem(item)}</p>
                    <p className='pausedBox__date'>
                      {moment(item.created_at).format('DD MMMM YYYY, HH:mm')}
                    </p>
                  </div>
                  <div className='pausedBox__date-box'>
                    <p className='pausedBox__text'>{item.currentStage.name}</p>
                    <Tooltip
                      placement='bottomLeft'
                      title={item.pauseComment}
                      arrowPointAtCenter
                      color='#293452'
                      overlayStyle={{ borderRadius: '6px' }}
                    >
                      <Button
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          height: 17,
                          paddingRight: 0,
                          paddingLeft: 8,
                        }}
                      >
                        <MessageOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </li>
              </div>
            )
          })
        ) : (
          <div className='notasks'>{t('main.tab.pendingRequests.noData')}</div>
        )}
      </ul>
    </>
  )
}

export default CalendarBlock
