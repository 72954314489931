import { Tooltip, Typography, Button, Space, DatePicker } from 'antd'
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons'
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { MaskedInput } from 'antd-mask-input'
import moment from 'moment'
import Counter from './counter'
import { numberWithSpaces, openModals } from '@utils'
import { SET_APP } from '@store/actions/methods'
import _ from 'lodash'
import { t } from 'i18next'

const { Text } = Typography
const { RangePicker } = DatePicker

export const columns = (
  dispatch,
  stockType,
  filters,
  markId,
  carModelId,
  filteredValue,
  tableSettings,
  moreDisabled
) => {
  const inputLength = (dataIndex) => {
    if (dataIndex === 'mileage') {
      return 9
    } else if (dataIndex === 'year') {
      return 4
    } else if (dataIndex === 'price') {
      return Infinity
    } else if (dataIndex === 'engine_capacity') {
      return 3
    }
  }

  const setMasks = (dataIndex) => {
    if (
      dataIndex === 'mileage' ||
      dataIndex === 'year' ||
      dataIndex === 'price'
    ) {
      return Number
    } else {
      let rgx = /^[0-9]*\.?[0-9]*$/
      return rgx
    }
  }

  const getMilleage = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <MaskedInput
          mask={[
            {
              mask: setMasks(dataIndex),
              min: 0,
              radix: '.',
              thousandsSeparator: ' ',
            },
          ]}
          placeholder={t('choiceProduct.filters.mileagePlaceholder')}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.unmaskedValue ? [e.unmaskedValue] : [])
          }}
          allowClear
          maxLength={inputLength(dataIndex)}
        />
        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#0989ff' : undefined,
        }}
      />
    ),
  })

  const getFromTo = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <Space>
          <MaskedInput
            mask={[
              dataIndex === 'price'
                ? {
                    mask: setMasks(dataIndex),
                    min: 0,
                    radix: '.',
                    thousandsSeparator: ' ',
                  }
                : {
                    mask: setMasks(dataIndex),
                    min: 0,
                  },
            ]}
            placeholder={t('choiceProduct.filters.beforePlaceholder')}
            value={selectedKeys[0]}
            onChange={(e) => {
              if (e.target.value) {
                dataIndex === 'price'
                  ? (selectedKeys[0] = e.unmaskedValue)
                  : (selectedKeys[0] = e.target.value)

                setSelectedKeys([selectedKeys[0]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            maxLength={inputLength(dataIndex)}
            style={{ width: dataIndex === 'price' ? 200 : 100 }}
          />
          <MaskedInput
            mask={[
              dataIndex === 'price'
                ? {
                    mask: setMasks(dataIndex),
                    min: 0,
                    radix: '.',
                    thousandsSeparator: ' ',
                  }
                : {
                    mask: setMasks(dataIndex),
                    min: 0,
                  },
            ]}
            placeholder={t('choiceProduct.filters.afterPlaceholder')}
            value={selectedKeys[1]}
            onChange={(e) => {
              if (e.target.value) {
                dataIndex === 'price'
                  ? (selectedKeys[1] = e.unmaskedValue)
                  : (selectedKeys[1] = e.target.value)

                setSelectedKeys([selectedKeys[0], selectedKeys[1]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            maxLength={inputLength(dataIndex)}
            style={{ width: dataIndex === 'price' ? 200 : 100 }}
          />
        </Space>

        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
  })

  const getStockDate = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <RangePicker
          format={'DD.MM.YYYY'}
          placeholder={[
            t('choiceProduct.filters.startPlaceholder'),
            t('choiceProduct.filters.endPlaceholder'),
          ]}
          inputReadOnly
          size='small'
          onChange={(e) => setSelectedKeys(e)}
          value={selectedKeys}
        />
        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
    filterIcon: (filtered) => (
      <CalendarOutlined
        style={{
          color: filtered ? '#0989ff' : undefined,
        }}
      />
    ),
  })

  let data = [
    {
      title: t('warehouse.tab.cars.column.brand'),
      dataIndex: 'carMark',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carMark?.name ? record?.carMark?.name : null}
        >
          {record?.carMark?.name ? record?.carMark?.name : null}
        </Tooltip>
      ),
      sorter: true,
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.markData?.length
        ? filters?.markData?.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filteredValue: filteredValue?.carMark || null,

      filterSearch: true,
    },
    {
      title: t('warehouse.tab.cars.column.model'),
      dataIndex: 'carModel',
      width: 140,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.carModel?.name ? record?.carModel?.name : null}
        >
          {record?.carModel?.name ? record?.carModel?.name : null}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters:
        markId && filters?.modelData?.length
          ? filters?.modelData?.map((item) => {
              const text = item.name
              const value = item.id
              const children = item.carModels?.map((i) => {
                const text = i.name
                const value = i.id

                return {
                  text,
                  value,
                }
              })

              return {
                text,
                value,
                children,
              }
            })
          : [],
      filteredValue: filteredValue?.carModel || null,
      filterSearch: true,

      // filterMode: 'tree',
    },
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.equipment'),
          dataIndex: 'carTemplate',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={
                record?.carTemplate?.name ? record?.carTemplate?.name : null
              }
            >
              {record?.carTemplate?.name ? record?.carTemplate?.name : null}
            </Tooltip>
          ),
          width: 200,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          filters:
            carModelId && filters?.templatesData?.length
              ? filters?.templatesData?.map((item) => {
                  const text = item.name
                  const value = item.id
                  const children = item.carTemplates?.map((i) => {
                    const text = i.name
                    const value = i.id

                    return {
                      text,
                      value,
                    }
                  })

                  return {
                    text,
                    value,
                    children,
                  }
                })
              : [],
          filteredValue: filteredValue?.carTemplate || null,

          // filterSearch: true,
          // filterMode: 'tree',
        }
      : {},
    {
      title: t('warehouse.tab.cars.column.vin'),
      dataIndex: 'vin_code',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 180,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.cars.column.bodyColor'),
      dataIndex: 'carExteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carExteriorColor?.name
              ? record?.carExteriorColor?.name
              : null
          }
        >
          {record?.carExteriorColor?.name
            ? record?.carExteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.exteriorColors?.length
        ? filters?.exteriorColors?.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filteredValue: filteredValue?.carExteriorColor || null,
    },
    {
      title: t('warehouse.tab.cars.column.interiorColor'),
      dataIndex: 'carInteriorColor',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carInteriorColor?.name
              ? record?.carInteriorColor?.name
              : null
          }
        >
          {record?.carInteriorColor?.name
            ? record?.carInteriorColor?.name
            : null}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.interiorColors?.length
        ? filters?.interiorColors?.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filteredValue: filteredValue?.carInteriorColor || null,
    },
    {
      title: t('warehouse.tab.cars.column.year'),
      dataIndex: 'year',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      ...getFromTo('year'),
      filteredValue: filteredValue?.year || null,
    },
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.status'),
          dataIndex: 'status',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={() => {
                if (text === 'in_stock') {
                  return t('warehouse.tab.cars.column.inStockStatus')
                } else if (text === 'in_transit') {
                  return t('warehouse.tab.cars.column.inTransitStatus')
                } else if (text === 'in_production') {
                  return t('warehouse.tab.cars.column.inProductionStatus')
                }
              }}
            >
              {text === 'in_stock'
                ? t('warehouse.tab.cars.column.inStockStatus')
                : ''}
              {text === 'in_transit'
                ? t('warehouse.tab.cars.column.inTransitStatus')
                : ''}
              {text === 'in_production'
                ? t('warehouse.tab.cars.column.inProductionStatus')
                : ''}
            </Tooltip>
          ),
          width: 140,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          filters: filters?.statusesData?.length
            ? filters?.statusesData?.map((item) => {
                const text = item.name
                const value = item.short_name

                return {
                  text,
                  value,
                }
              })
            : [],

          filteredValue: filteredValue?.status || null,
        }
      : {},
    stockType === 'new'
      ? {
          title: t('warehouse.tab.cars.column.arrivalDate'),
          dataIndex: 'stock_date',
          render: (text) => (
            <Tooltip
              placement='topLeft'
              title={text ? moment(text).format('DD MMMM YYYY') : null}
            >
              {text ? moment(text).format('DD MMMM YYYY') : null}
            </Tooltip>
          ),
          width: 165,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          ...getStockDate(),
          filteredValue: filteredValue?.stock_date || null,
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.responsiblePerson'),
      dataIndex: 'user',
      width: 200,

      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.user?.full_name ? record?.user?.full_name : null}
        >
          {record?.user?.full_name ? record?.user?.full_name : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
    },

    {
      title: t('warehouse.tab.cars.column.client'),
      dataIndex: 'client',
      width: 150,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={record?.client?.full_name ? record?.client?.full_name : null}
        >
          {record?.client?.full_name ? record?.client?.full_name : null}
        </Tooltip>
      ),
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
    },

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.terms'),
          dataIndex: 'conditions',
          render: (text, record) => (
            <Tooltip
              placement='topLeft'
              title={
                record?.conditions !== null
                  ? record?.conditions?.map((item, index) => {
                      return <p key={index}>{item}</p>
                    })
                  : ''
              }
            >
              {record?.conditions !== null
                ? record?.conditions?.map((item, index) => {
                    return <Text key={index}>{item}</Text>
                  })
                : ''}
            </Tooltip>
          ),
          width: 140,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          filters: filters?.conditions?.length
            ? filters?.conditions?.map((item) => {
                return item
              })
            : [],
          filteredValue: filteredValue?.conditions || null,
        }
      : {},

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.mileage'),
          dataIndex: 'mileage',
          render: (text) => (
            <Tooltip placement='topLeft' title={`${numberWithSpaces(text)} `}>
              {`${numberWithSpaces(text)} `}
            </Tooltip>
          ),
          width: 140,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },

          ...getMilleage('mileage'),
          filteredValue: filteredValue?.mileage || null,
        }
      : {},

    stockType !== 'test_drive'
      ? {
          title: t('warehouse.tab.cars.column.price'),
          dataIndex: 'price',
          render: (text) => (
            <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
              {`${numberWithSpaces(text)}`}
            </Tooltip>
          ),
          width: 160,
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          ...getFromTo('price'),
          filteredValue: filteredValue?.price || null,
        }
      : {},

    {
      title: t('warehouse.tab.cars.column.engine'),
      dataIndex: 'engine_capacity',
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      width: 160,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      ...getFromTo('engine_capacity'),
      filteredValue: filteredValue?.engine_capacity || null,
    },
    {
      title: t('warehouse.tab.cars.column.gearboxType'),
      dataIndex: 'carGearboxType',
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.carGearboxType?.short_value
              ? record?.carGearboxType?.short_value
              : null
          }
        >
          {record?.carGearboxType?.short_value
            ? record?.carGearboxType?.short_value
            : null}
        </Tooltip>
      ),
      width: 120,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          disabled={moreDisabled}
          onClick={() =>
            !moreDisabled &&
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: stockType === 'spare_parts' ? true : false,
            })
          }
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
      fixed: 'right',
    },
  ]

  data = data.filter(
    (item) =>
      tableSettings?.includes(item.dataIndex) || item.dataIndex === 'action'
  )

  return data
}

export const columnsForSpareParts = (
  dispatch,
  stockType,
  dataForSparePartTask,
  dataForSparePartNeeds,
  setDataForSparePartTask,
  setDataForSparePartNeeds,
  needsData,
  setTotalPrice,
  filters,
  tableSettings,
  moreDisabled
) => {
  const getFromTo = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Space
        direction='vertical'
        align='end'
        style={{
          padding: 12,
        }}
      >
        <Space>
          <MaskedInput
            mask={[
              {
                mask: Number,
                min: 0,
                radix: '.',
                thousandsSeparator: ' ',
              },
            ]}
            placeholder={t('choiceProduct.filters.beforePlaceholder')}
            value={selectedKeys[0]}
            onChange={(e) => {
              if (e.target.value) {
                selectedKeys[0] = e.unmaskedValue

                setSelectedKeys([selectedKeys[0]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            style={{ width: 200 }}
          />
          <MaskedInput
            mask={[
              {
                mask: Number,
                min: 0,
                radix: '.',
                thousandsSeparator: ' ',
              },
            ]}
            placeholder={t('choiceProduct.filters.afterPlaceholder')}
            value={selectedKeys[1]}
            onChange={(e) => {
              if (e.target.value) {
                selectedKeys[1] = e.unmaskedValue

                setSelectedKeys([selectedKeys[0], selectedKeys[1]])
              } else {
                setSelectedKeys([])
              }
            }}
            allowClear
            style={{ width: 200 }}
          />
        </Space>

        <Button
          type='primary'
          size='small'
          onClick={() => {
            confirm()
          }}
        >
          OK
        </Button>
      </Space>
    ),
  })

  let addPart = (increment, record, part) => () => {
    let item = {}
    let partNeeds = [...dataForSparePartNeeds]
    const partIndex = _.findIndex(dataForSparePartNeeds, { id: record.id })

    if (part) {
      item = { ...part }
      item.orderAmount = item.orderAmount + increment
      item.amount = item.amount - increment
      item.reserve = item.reserve + increment
      partNeeds[partIndex] = { ...item }
    } else {
      item = { ...record }
      item.orderAmount = 1
      item.amount = item.amount - increment
      item.reserve = item.reserve + increment
      partNeeds = [...partNeeds, item]
    }
    dispatch(SET_APP(['dataSpareNeeds'], partNeeds))
    console.log(partNeeds)
  }

  let data = [
    {
      title: '',
      dataIndex: 'count',
      render: (text, record) => {
        const part = _.find(dataForSparePartNeeds, { id: record.id })
        let amount = 0
        if (part) amount = part.orderAmount

        let minusDisabled = false
        let plusDisabled = false

        if (amount === 0) minusDisabled = true
        if (amount >= record.amount && !record.order_amount) plusDisabled = true
        if (
          record.order_amount &&
          record.amount + record.order_amount === amount
        )
          plusDisabled = true
        return (
          <Space size={16}>
            <Button
              type='link'
              disabled={minusDisabled}
              onClick={addPart(-1, record, part)}
            >
              <MinusSquareOutlined style={{ fontSize: 20 }} />
            </Button>
            <Text>{amount}</Text>
            <Button
              type='link'
              disabled={plusDisabled}
              onClick={addPart(1, record, part)}
            >
              <PlusSquareOutlined style={{ fontSize: 20 }} />
            </Button>
          </Space>
        )
      },
      width: 120,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.category'),
      dataIndex: 'sparePartCategory',
      width: 220,
      render: (text, record) => (
        <Tooltip
          placement='topLeft'
          title={
            record?.sparePartCategory?.name
              ? record?.sparePartCategory?.name
              : null
          }
        >
          {record?.sparePartCategory?.name
            ? record?.sparePartCategory?.name
            : null}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      filters: filters?.sparePartsCategory?.length
        ? filters?.sparePartsCategory?.map((item) => {
            const text = item.name
            const value = item.id

            return {
              text,
              value,
            }
          })
        : [],
      filterSearch: true,
    },
    {
      title: t('warehouse.tab.parts.column.name'),
      dataIndex: 'name',
      width: 280,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.article'),
      dataIndex: 'article_number',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={text}>
          {text}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.inStock'),
      dataIndex: 'amount',
      width: 160,
      render: (text, record) => {
        const part = _.find(dataForSparePartNeeds, { id: record.id })
        let amount = 0
        if (part) {
          amount = text - part.orderAmount
        } else {
          amount = text
        }

        if (record.order_amount) amount = amount + record.order_amount
        return (
          <Tooltip placement='topLeft' title={`${numberWithSpaces(amount)} `}>
            {`${numberWithSpaces(amount)} `}
          </Tooltip>
        )
      },
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.reserve'),
      dataIndex: 'reserve',
      width: 160,
      render: (text, record) => {
        const part = _.find(dataForSparePartNeeds, { id: record.id })
        let reserve = 0
        if (part) {
          reserve = text + part.orderAmount
        } else {
          reserve = text
        }
        if (record.order_amount) reserve = reserve - record.order_amount

        return (
          <Tooltip placement='topLeft' title={`${numberWithSpaces(reserve)} `}>
            {`${numberWithSpaces(reserve)} `}
          </Tooltip>
        )
      },
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('warehouse.tab.parts.column.price'),
      dataIndex: 'price',
      width: 160,
      render: (text) => (
        <Tooltip placement='topLeft' title={`${numberWithSpaces(text)}`}>
          {`${numberWithSpaces(text)}`}
        </Tooltip>
      ),
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      ...getFromTo(),
    },

    {
      title: t('warehouse.tab.column.action'),
      dataIndex: 'action',
      render: (text, record, index) => (
        <Button
          type='link'
          style={{ padding: 0 }}
          disabled={moreDisabled}
          onClick={() =>
            !moreDisabled &&
            openModals(dispatch, {
              type: 'product-details',
              id: record.id,
              spareStatus: stockType === 'spare_parts' ? true : false,
            })
          }
        >
          {t('warehouse.tab.column.actionBtn')}
        </Button>
      ),
      width: 140,
    },
  ]

  data = data.filter(
    (item) =>
      tableSettings?.includes(item.dataIndex) ||
      item.dataIndex === 'action' ||
      item.dataIndex === 'count'
  )

  return data
}
