import React, { useState } from 'react'
import { Layout, Divider } from 'antd'
import './index.less'
import { ClientInfo } from './components'
import { ClientHistory } from './components'
import Loading from '@ui/Loading'

const Index = (props) => {
  const [client, setClient] = useState()
  const { taskData, setTaskData, id, loading } = props

  return (
    <div>
      {!loading && (
        <Layout.Content className='task__action-history'>
          <ClientInfo
            data={{
              taskData,
              client,
              setClient,

              setTaskData,
              id,
            }}
          />
          <Divider style={{ margin: 0 }} />

          <ClientHistory data={{ taskData, client, setClient }} />
        </Layout.Content>
      )}
    </div>
  )
}

export default Index
