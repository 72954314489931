import { Button, notification, Drawer, message } from 'antd'
import React, { useState, useContext, forwardRef } from 'react'
import { useImperativeHandle, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import SkeletonTable from '@ui/SkeletonTable'
import { useDispatch } from 'react-redux'
import { autoColumns, columnsForSpareParts } from './columns'
import _ from 'lodash'
import { numberWithSpaces, SSE } from '@utils'
import { ModuleContext } from '../../..'
import { useTranslation } from 'react-i18next'

const ChoiceProduct = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { module, taskData, currentNeeds, cardTitle, needsData, setNeedsData } =
    useContext(ModuleContext)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  useImperativeHandle(
    ref,
    () => ({
      getStatus: async () => {
        if (module?.isRequired) return currentNeeds?.meta?.length > 0
        else return true
      },
    }),
    [currentNeeds]
  )

  const MODULE_CHOICE_PRODUCT = `MODULE_CHOICE_PRODUCT_${taskData?.mainTaskId}_${module?.settings.stockType}`

  const onUpdated = (e) => {
    const event = JSON.parse(e.data)
    const { data } = event

    message.error(t('task.moduleRender.module.choiceProduct.errorMessage'))

    data?.forEach((x) => {
      let index = _.findIndex(needsData, (i) => x.module?.id === i.module?.id)

      if (index !== -1) {
        needsData[index] = x

        setNeedsData([...needsData])
      }
    })
  }

  const sse = useRef()

  useEffect(() => {
    const sse_init = async () => {
      sse.current = await dispatch(SSE())
      sse.current.addEventListener(MODULE_CHOICE_PRODUCT, onUpdated)
    }
    sse_init()
    return () => {
      if (sse.current) {
        sse.current.removeEventListener(MODULE_CHOICE_PRODUCT, onUpdated)
      }
    }
  }, [])

  const onClose = () => {
    setOpen(false)
  }

  const setPartsPrice = () => {
    let price = 0
    currentNeeds?.meta.forEach((item) => {
      price += item.orderAmount * item.price
    })

    return price
  }

  const _totalPrice =
    module?.settings.stockType !== 'spare_parts'
      ? _.sumBy(currentNeeds?.meta, 'price')
      : setPartsPrice()

  return (
    <>
      {cardTitle}
      <Button
        shape='round'
        ghost
        type='primary'
        onClick={() => {
          if (taskData?.client?.id) {
            !currentNeeds?.meta?.length
              ? navigate(`/choice-product/${taskData?.id}/${module.id}`)
              : setOpen(true)
          } else
            notification.warning({
              message: t('task.moduleRender.module.choiceProduct.warnMessage'),
            })
        }}
      >
        {!currentNeeds?.meta?.length
          ? t('choiceProdict.table.selectBtn')
          : `${t('task.moduleRender.module.choiceProduct.selected')} ${
              currentNeeds?.meta?.length
            }`}
      </Button>
      <Drawer
        height={480}
        title={t('task.moduleRender.module.choiceProduct.selectedCars')}
        placement='bottom'
        onClose={onClose}
        // visible={open}
        open={open}
        bodyStyle={{ padding: 0 }}
        extra={
          <Button
            shape='round'
            type='primary'
            onClick={() =>
              navigate(`/choice-product/${taskData?.id}/${module.id}`)
            }
          >
            {t('task.moduleRender.module.choiceProduct.changeBtn')}
          </Button>
        }
        footer={
          module?.settings.stockType !== 'test_drive' && (
            <div
              style={{
                fontWeight: 500,
                fontSize: 20,
                padding: '18px 8px',
              }}
            >
              {t('warehouse.totalPrice')} {`${numberWithSpaces(_totalPrice)}`}
            </div>
          )
        }
      >
        <SkeletonTable
          take={20}
          columns={
            module?.settings.stockType !== 'spare_parts'
              ? autoColumns(
                  dispatch,
                  module?.settings.stockType,
                  module?.settings.tableColumns
                ).filter((col) => Object.keys(col).length != 0)
              : columnsForSpareParts(
                  dispatch,
                  module?.settings.stockType,
                  module?.settings.tableColumns
                )
          }
          data={currentNeeds?.meta}
          xScroll={module?.settings.stockType !== 'spare_parts' ? true : false}
          className='choice-product__table'
          width={document.body.offsetWidth - 56}
          height={module?.settings.stockType !== 'test_drive' ? 260 : 338}
        />
      </Drawer>
    </>
  )
})

export default ChoiceProduct
